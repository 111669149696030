

.viewcard{
    width:900px;
    /* background-color: '#FFFFFF'; */
}

.describeproblem{
    border-radius: 8px;
    border: 1px solid #8692A6;
    background-color: #F8F8F8;
    height: 170px;
}
.borderdashed{
    border-radius: 16px;
    border: 2px solid #8692A6;
    background-color: #F8F8F8;
    padding: 80px;
}
.borderdashed2{
  border-radius: 16px;
  border: 2px solid #8692A6;
  background-color: #F8F8F8;
}

.new-textinput{
    height: 56px;
    background-color:#F8F8F8 ;
    border: 1px solid #D6D6D6;
    border-radius: 8px;
  }

  .tablesupport {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    border: 1px solid rgba(205, 205, 205, 1);
}

.custom-table thead th:first-child {
    border-top-left-radius: 12px;
  }
  
  .custom-table thead th:last-child {
    border-top-right-radius: 12px;
  }
  
  .custom-table th, .custom-table td {
    border: 1px solid #ddd;
    padding: 15px;
  }
  .custom-table th{
    background-color: "#D6D6D6";
  }
  
  .custom-table {
    border-collapse: separate;
    border-spacing: 0;
  }

  .tablesupport {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    border: 1px solid rgba(205, 205, 205, 1);
} 
  
  .custom-table .last-row td:first-child {
    border-bottom-left-radius: 12px;
  }
  
  .custom-table .last-row td:last-child {
    border-bottom-right-radius: 12px;
  }
  
  .last-row{
    background-color: white;
  }
/* .border{
    height: 55px;
    border:1px solid #C2C9D1;
    border-radius: 5px;
} */

.assignagent{
    width: 120px;
    height: 50px;
    border-radius: 5px;   
}

.select-btn>.select-colum {
    width: 170px;
    background-color: white;
    border: 2px solid #03565A;
    padding: 15px;
}