
.containerQuestionnaire{
    display: flex;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    /* min-width: 1500px; */
}
.track{
    height: 100%;
    /* min-height: 100vh; */
    background-color: var(--clr-bg);
    display: flex;
    justify-content: center;
    padding-top: 10rem;
}
.progressBar{
    display: flex;

}
.track1{
    margin: 0 1rem 3.5rem 1rem;
}
.track1>h4{
    font-size: 1.2rem;
    font-weight: 600;
}
.track1>p{
    font-size: 1rem;
    font-weight: 300;
    margin-top: 0;
}

.vector-1{
    width:42px ;
    height: 21rem;
    background-image: url('../../assets/sidebar-1.svg');
}
.vector-2{
    width:42px ;
    height: 21rem;
    background-image: url('../../assets/sidebar-2.svg');
}

.vector-3{
    width:42px ;
    height: 21rem;
    background-image: url('../../assets/sidebar-3.svg');

}

.form-control-lg{
    height: 49px;
    font-size: 1rem;
    border-radius: 6px;
}

.main{
    min-height: 100vh;
    padding:4rem;
}

.inputs{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.tab-1,.tab-2{
    width: 340px;
}
.react-datepicker__input-container>input[type=text] {
    width: 100%;
    padding: 12px 10px;
    font-size: 18px;
    font-family: 'DM Sans';
    display: inline-block;
    border: none;
    border-radius: 6px;
    margin-top: 0;
    background: transparent;
}

/* .post>input[type=submit] {
    width: 100%;
    background-color: var(--clr-accent);
    color: white;
    padding: 23px 15px;
    font-size: 17px;
    font-family: 'DM Sans';
    margin: 10px 0;
    border: none;
    border-radius: 6px;
} */

.Date{
    border: 1px solid var(--clr-font-light);
    align-items: center;
    border-radius: 6px;
    height: 52px;

}
.Date>img{
   
    margin: 0 10px;

}
.post{
    padding: 0 12rem;
    margin-top: 4rem;
}
.header>h1{
    font-size: 1.7rem;
    font-weight: 800;
    margin-bottom: 50px;
}
.check-picker{
    margin-top: 4.5rem;
}

.suffix-format{
    font-size: 1rem;
    font-weight: 500;
    color: var(--clr-font-mid);
    text-align: center;
}
.sufix-small{
    display: flex;
    justify-content: center;
    color: var(--clr-font-semi);
}

.form-group>p,.in-1>p,.date-picker>p{
    margin: 0;
    margin-bottom: 0;
    font-size: 0.9rem;
    color: var(--clr-font-mid);
}
.form-group>p>span,.in-1>p>span,.date-picker>p>span{
    font-size: 1rem;
    color: red;
}
.in-1,.date-picker{
    margin-bottom: 25px;
}

.DnD{
    margin-top: 25px;
    height: 18rem ;
    border: 2px dashed var(--clr-accent);
    border-radius: 10px;


}

.folder{
   margin-top: 5rem;
   display: flex;
   justify-content: center;
}
.DnD>h3{
    text-align: center;
    font-size: 1.2rem;
    color: var(--clr-font-mid);
}
.DnD>p{
    text-align: center;
    font-size: 0.8rem;
    color: var(--clr-font-semi);
    margin-top: 0;
}
.file-show{
    display: flex;
    margin-top: 0rem;
}

.upload-TB{
    width: 50%;
    margin-left: 11rem;
    margin-top: 3rem;
    margin-bottom: 0;

}

@media (min-width: 992px){
    .modal-xl {
        max-width: 1100px;
    }
}

.input-map{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-column-gap: 3rem;
    grid-row-gap: 10px;
}


.map-input>Form>.form-group>.form-control-lg{
    height: 3rem;
    margin-top: 0;
    border: 1px solid var(--clr-font-light);
}

.mapping-container{
    padding: 0rem 2rem;
    padding: 2rem ;
    background-color: rgba(241, 241, 241, 0.863);
    border-radius: 10px;
}
.mapHeader>p{
    color: var(--clr-font-mid);
    font-weight: 400;
}

.map-input p{
    color: var(--clr-font-mid);
    font-weight: 400;
    margin: 0;
    font-size: 0.9rem;

}
.map-input p span{
    font-size: 1rem;
    color: red;

}
.map-input input[type=text] {
    width: 100%;
    padding: 9px 10px;
    font-size: 18px;
    font-family: 'DM Sans';
    margin-top: 0px;
    display: inline-block;
    border: 1.3px solid var(--clr-font-light);
    border-radius: 6px;


}

.map-input input[type=text]:focus-visible{
    outline: none;
    border: 2px solid #03565A;
    box-shadow: -1px -1px #cacacabf;

}

.map-button{
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}


.submit-btn{
    margin-left: 2rem;
}
.close-btn{
    font-weight: 600;
    color: var(--clr-accent);
    text-decoration: none;
    text-emphasis: none;
}
/* .submit-btn>input[type=submit] {
    width: 100%;
    background-color: blueviolet ;
    color: white;
    padding: 10px 40px;
    font-size: 17px;
    font-family: 'DM Sans';
    margin: 10px 0;
    border: none;
    border-radius: 6px;
} */

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0rem ;
}
.modal-content {
    border-radius: 0.8rem;
}

.file-name>div>h1{
    font-size: 2rem;
}
.file-name>div>p{
    padding: 0;
    margin-top: 0.5rem;
}

/* option {
    font-weight: normal;
} */


.modeHeader{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.modeHeader>h1{
    display: flex;
    justify-content: center;
    font-size: 1.9rem;
    font-weight: 700;


}
.modeHeader>p{
    margin-top: 0px;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    font-weight: 400;
    color: var(--clr-font-mid);
    margin-bottom: 0;

}

.modeImg-1{
    height: 10.8rem;
    width: 17.5rem;
    border: 1px solid var(--clr-font-light);
    border-radius: 1rem;
    cursor: pointer;


    background-image: url('../../assets/cMode.svg');
}
.modeImg-2{
    height: 10.8rem;
    width: 17.5rem;
    border: 1px solid var(--clr-font-light);
    border-radius: 1rem;
    cursor: pointer;

    background-image: url('../../assets/tMode.svg');
}

.modeImg-2:hover{
    border: 2px solid var(--clr-accent);

}
.modeImg-1:hover{
    border: 2px solid var(--clr-accent);

}


.modeImg-2_active{
    height: 10.8rem;
    width: 17.5rem;
    border: 1px solid var(--clr-font-light);
    border-radius: 1rem;
    cursor: pointer;
    border: 2px solid #03565a;
    background-image: url('../../assets/tMode.svg');

}
.modeImg-1_active{
    height: 10.8rem;
    width: 17.5rem;
    border: 1px solid var(--clr-font-light);
    border-radius: 1rem;
    cursor: pointer;
    border: 2px solid #03565a;
    background-image: url('../../assets/cMode.svg');

}
.in-1>input{
    height: 48px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #cacacabf;
    padding: 10px;
}
.in-1>input:focus-visible{
    outline: none;
    border: 2px solid #03565A;
    box-shadow: -1px -1px #cacacabf;
}


.cMode,.tMode{
    margin-top: 2rem;
    display: flex;
}

.modeContent{
    padding: 2rem;
}
.modeContent>h1{
    font-size: 1.1rem;
    font-weight: 600;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 8px;
    margin-right: .3125rem;
    margin-left: 0;
}

.modeContent>p{
    margin: 0;
    font-size: 0.8rem;
    color: var(--clr-font-semi);
    font-weight: 400;
}
/*select styling*/

.react-date-picker__wrapper{
    height: 48px;
    border-radius: 6px;
    width: 21rem;
    /* border-color: hsl(0, 0%, 80%); */
    border: 1px solid var(--clr-font-light) !important;
}
