body {
  background-color: #f5f5f5 !important;
}

.backk {
  margin-top: 130px;
  color: #03565A;
  display: flex;
  flex-direction: row;
  height: 1.3rem;
  width: 2rem;
  margin-left: 4.5%;
}

.backk>.iconn {
  color: #03565A;
  height: 1.3rem;
  width: 2rem;

}

.companies {
  margin-left: 5%;
  margin-top: 10px;
}

.companies>.text {
  font-weight: bold;
}

.paragraph {
  color: gray;
  margin-left: 5%;
  font-size: x-small;

}

.paragraph>.fontsize {
  font-size: 0.8rem;
}

.searchbar {
  /* height: 1rem; */
  margin-left: 5%;
  margin-top: 28px;
  /* width: 50.5%; */


  /* style={{ marginLeft: "5%", marginTop: 10 }} */
}

.searchbar>.searchbox {
  width: 50.5%;

  background-color: white;

}

.figma {
  margin-top: 15px;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 48%;
  height: 44px;
  border-radius: 10px;
  background-color: rgb(3 89 93 / 5%);

}

.secondfigma {
  margin-top: 15px;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  height: 44px;
  border-radius: 10px;
  background-color: rgb(3 89 93 / 5%);
}



.figma>.icon {
  height: 50;
  width: 20;

}

.MuiChip-root {
  color: #03565A !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
  padding: 0px 10px !important;
  border: 2px solid #03565A !important;
  background: rgba(3, 86, 90, 0.10);
}

.MuiChip-deleteIcon {
  color: #03565A !important;
  font-size: 16px !important;
  cursor: pointer;
  margin: 0 5px 0 -6px;
  font-weight: 500;
}

.button {
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  margin-top: 8rem;
  margin-bottom: 2rem;
}

.button>.lastbtn {

  width: 150px;
  height: 56px;
  background-color: white;
  margin-left: 20px;
  color: #03565A;
  border: 2px solid #03565A;
  border-radius: 6px;


}

/* .btnsize{
    margin-top: 15px;
  
    font-size: 1rem;
    text-align: center;
  
    
    
  } */
.endbtn {
  margin-left: 60px;
  font-weight: 600;
  /* style={{ marginLeft: "60px", fontWeight: "600" }} */
}

.button>.btnsize {

  width: 150px;
  height: 55px;
  background-color: white;
  color: #03565A;
  text-transform: none;
  font-size: 1rem;
  margin-left: 25px;
  /* font-size: medium; */
  /* border-radius: 5px; */



}
.save-workspacesetting-btn{
  color: white !important;
  background-color: #03565a !important;
  border-radius: 4px;
  border: none;
  width: 198px;
  height: 45px;
  text-transform: capitalize !important;
  margin-left:80px !important;
}
.viewlog-workspacesetting-btn{
  color: white !important;
  background-color: #03565a !important;
  border-radius: 4px;
  border: none;
  width: 198px;
  height: 45px;
  text-transform: capitalize !important;
}

.button>.btnsize:hover {
  background-color: white;

}

.button>.continue {

  width: 250px;
  height: 55px;
  background-color: #03565A;
  color: white;
  text-transform: none;
  font-size: 1rem;
  border-radius: 5px;


}

.button>.continue2 {

  width: 250px;
  height: 55px;
  border-radius: 7px;
  background-color: gray;
  color: white;
  text-transform: none;
  font-size: 1rem;


}

.button>.continue:hover {
  background-color: #03565A;
}

.button>.continue2:hover {
  background-color: gray;
}

.css-aeeyth-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.5rem !important;
}

/* dashboard css */
.dahboardHeaderV2 {
  margin-top: 30px;
  padding: 0 70px;
}

.dbv2datareg {
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
}

.dv2datapar {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
}

.dahboardCardV2 {
  /* width:1434px; */
}

.dashboard-h3 {
  color: #000;
  text-align: center;
  /* H3/Bold */
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 38.4px */
  letter-spacing: 0.64px;
}

.dbv2hp {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;

}

/* .dashboardlogoV2{
    display: flex;
    flex-direction: row;
    margin-left: 254px;
    margin-top: 52px,
} */
.dashboardContainerV2 {
  margin-top: 7rem;
}

.colv2 {

  margin-left: -150px;

}

.dashboardButton1 {
  background-color: #03565A;
}

.dashboardButton2 {


  background-color: white;
  color: #03565A;
  border: 2px solid #03565A;
  margin-left: 10px;
}

.col2V2 {
  margin-top: -18px;
}

.db-empty-company-card {
  padding: 36px 0px;
}

.db-wp-empty-p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.db-wp-empty-logos {
  padding: 35px 0px;
}

.wp-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
  overflow: auto;
}

.wp-modal-box-add-company {
  height: calc(80vh);
  width: 789px;
}

.wp-modal-box-select-ir {
  height: calc(85vh);
  width: 600px;
  background-color: #F8F8F8 !important;
}

.wp-modal-box-select-ir:focus-visible {
  outline: none !important;
}

.wp-modal-box-select-ir-closebtn {
  color: #000;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
  float: right;
}

.wp-add-comp-modal-header {
  color: #000;
  /* H5/Bold */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 28.8px */
  letter-spacing: 0.48px;
}

.wp-add-comp-modal-p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 0px
}

.wp-add-comp-modal-search {
  width: 100%;
  margin-top: 15px !important;
}

.wp-add-comp-modal-input {
  width: 100%;
}

.MuiInputBase-input {
  margin-top: 0px !important;
  border-radius: 6px;
  border: 1px solid #8692A6;
  background: #FFF;
}

.wp-comp-search-no-reslt-msg {
  border-radius: 12px;
  background: rgba(3, 86, 90, 0.05);
  margin-top: 8px;
  padding: 13px;
  color: #03565A;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.wp-comp-added-p {
  color: #000;

  /* ParagraphMedium/Medium */
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.IR-tabs {
  background-color: #f4f4f4;
}

.css-ttwr4n {
  background-color: #f4f4f4 !important;
  position: initial !important;
}

.IR-tabs-card {
  border-top-left-radius: 40px !important;
  /* border-top-left-radius: 180px 180px !important; */
  padding: 20px !important;
}

.wp-IR-tabs {
  border-radius: 8px;
}

.wp-IR-tabs>.MuiTab-root {
  border-radius: 8px;
  color: red !important;
  font-size: 15px;
  font-weight: 500;
}

.cardbuttonv2 {

  background-color: white;
  color: #03565A;
  border: 2px solid #03565A;

}

.container2v2 {
  margin-top: 7rem;
}

.cardStyleV2 {
  height: 300px;
}

.dahboardCard2V2 {
  /* width: 35rem; */
  max-height: 325px;
  min-height: 325px;

}

.dahboardicon {
  color: gray;
  margin-left: 30px;
}

.ir-empty-cardheader {
  padding: 0;
  background-color: #fff;
  border-bottom: none;
}

/* .dashboardvi{
  border-right: 3px solid gray;

}  */
.dashboardheader {
  margin-left: 65px;
}

.dashboardchead {
  margin-left: 20px;
}

.dashboardnavit {
  background-color: #DDDDDD79;
}



.dbinput input::placeholder {

  padding-left: 40px;
}

.dbicon {

  margin-top: -46px;
  margin-left: 7px;

}

.dbbtdiv {
  margin-top: 61px;
  margin-left: 23px;
}

.dvtypo {
  font-size: 13px;
}

.dbwcontainer {
  margin-left: 263px;
}

.dbwscontain {
  margin-left: 112px;
}

.dbwcontain2 {
  margin-left: 127px;
}

.dbwbtn {
  margin-left: 368px
}

.dbwhead {
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
  color: #000000;
}

.dbwcardhead {
  color: #000;
  /* H5/Semibold */
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 28.8px */
  letter-spacing: 0.48px;
  display: inline-block;
}

.abcd1111 {
  float: right;
}

.dbwcardhead-viewall {
  color: #03565A;
  text-align: right;
  float: right;
  /* ParagraphMedium/Bold */
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
}

.db-wp-company-list span {
  color: #000;
  /* ParagraphMedium/Medium */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.wp-modal-box-add-company-form {
  height: calc(80vh);
  width: 689px;
}

.register-modal-card {
  box-shadow: none;
  border-radius: 20px !important;
  background: #FFF;
  padding-top: 15px;
}

.register-modal-card>.MuiCardContent-root {
  padding-top: 10px;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 0px !important;
}

.register-modal-card-active {
  border: 2px solid #03565A;
}

.dbwcardpar {
  color: #000;

  /* ParagraphMedium/Regular */
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 0px;
  /* 150% */
  padding-bottom: 10px;
}

.dbwcard2head {
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
  color: #000000;
}

.badge_css {
  background-color: #EDFFF3;
  border-radius: 5px;
  color: #068133;
  padding: 0px 10px;
}

.addcomphead {
  font-weight: 700;
  font-size: 28px;
  line-height: 33.6px;

}

.addcomppara {
  color: #939393;
  font-size: 16px;
  line-height: 24px;
}

.addcompconthead {
  margin-top: -25px;
  margin-left: 33px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #111111;
  height: 10px;
}
.addcompcontheadv2 {
  margin-top: -25px;
  margin-left: 33px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #111111;
  height: 10px;
  margin-left: 44px;
}
.toggl-icon{
  margin-left: 10px;
}


.fs-head {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.hide {
  display: none;
  position: fixed;
  margin-top: -1px;
}

.myDIV:hover+.hide {

  display: block;
  flex-direction: row-reverse;
  color: black;
  font-size: 13px;

}

.bdrchk {
  border: solid 1px #f00;
}

.wp-btn-fill {
  color: #fff !important;
  padding: 10px 60px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 6px !important;
  background: #03565A !important;
  border-color: #03565A !important;
  font-family: DM Sans !important;
  font-size: 14px !important;
}

.wp-btn-fill:hover,
.wp-btn-fill:focus,
.wp-btn-fill:active {
  color: #fff !important;
  background: #03565A !important;
  border-color: #03565A !important;
  box-shadow: none
}

.wp-btn-no-fill {
  color: #03565A !important;
  padding: 9px 59px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 6px !important;
  border: 2px solid #03565A !important;
  background: #FFF !important;
  font-family: DM Sans !important;
  font-size: 14px !important;
}

.wp-btn-no-fill:hover,
.wp-btn-no-fill:focus,
.wp-btn-no-fill:active {
  color: #03565A !important;
  background: #FFF !important;
  border-color: #03565A !important;
  box-shadow: none
}

.blankspace10 {
  padding: 10px 0px;
}

.blankspace10 {
  padding: 10px 0px;
}

.blankspace10 {
  padding: 10px 0px;
}

.blankspace10 {
  padding: 10px 0px;
}

.blankspace20 {
  padding: 20px 0px;
}

.blankspace30 {
  padding: 30px 0px;
}

.blankspace40 {
  padding: 40px 0px;
}

.blankspace50 {
  padding: 50px 0px;
}

.blankspace60 {
  padding: 60px 0px;
}

.blankspace70 {
  padding: 70px 0px;
}

.blankspace80 {
  padding: 80px 0px;
}

.blankspace90 {
  padding: 90px 0px;
}

.blankspace100 {
  padding: 100px 0px;
}


/* Tooltip Css Start */
.rtooltip {
  position: relative;
  display: inline-block;
}

.rtooltip .rtooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #f5f5f5;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  /* Position the tooltip */
  position: absolute;
  top: 27px;
  left: 0;
  z-index: 1;
  font-size: 12px;
  font-weight: 400;
  box-shadow: white 0px 8px 8px -4px;
}

.rtooltip:hover .rtooltiptext {
  visibility: visible;
}

/* Tooltip Css End */

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  box-shadow: lightblue 3px 4px 4px -3px !important;
}

.css-17ffvgn-MuiTypography-root {
  padding: 10px 15px !important;
}

.css-1eccwg7 {
  padding: 10px 15px !important;
}

.db-ir-tabs tr th {
  font-family: Inter !important;
}

.all-company thead th {
  font-family: Inter !important;
}

.open-radio-outer {
  width: 24px;
  height: 24px;
  background-color: #f3dbbe;
  border-radius: 50%;
}

.open-radio-inner {
  width: 11.74px;
  height: 11.74px;
  background-color: #fdc27b;
  border-radius: 50%;
  position: relative;
  top: 6px;
  left: 6px;
}

.viewIcon {
  width: 170px;
  height: 28px;
  display: flex;
  align-items: start;
  justify-content: start;
  background-color: #F8F9F9;
}

.view-options {
  width: 170px;
  height: 28px;
  display: flex;
  align-items: start;
  justify-content: start;
  margin-top: -23px;

}
.icon-img{
width: 18px;
height: 18px;
margin-top: 5px;

}
.rename-icons{
  color:#32a899;
  background-color:#f5f5f5;
  border-radius:5px;
  font-size: 10px;
  margin:5px;
  padding:5px;
  justify-content: center;
  align-items: center;
}
.view-icons{
  color:#5d615f;
  background-color:#f5f5f5;
  border-radius:5px;
  font-size: 10px;
  margin:5px;
  padding:5px;
  justify-content: center;
  align-items: center;
}

.css-1dmzujt{
  box-shadow: lightblue 3px 4px 4px -3px !important;
}
.notstarted{
color: #828282;
background-color:#F8F8F8 ;
height: 30px !important;
border-radius: 5px;
padding: 5px;
}
.inprogress{
color: #CC8000;
background-color: #FFF6E6;
height: 30px !important;
border-radius: 5px;
padding: 5px;
}
.completed{
color: #068133;
background-color: #EDFFF3;
height: 30px !important;
border-radius: 5px;
padding: 5px;
}