.page-wrapper.compact-wrapper .page-header {
    margin-left: 280px;
    width: calc(100% - 280px);
}

.page-wrapper {
    overflow: hidden;
}

.page-wrapper .page-header .header-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 22px 30px;
    position: relative;
}
.logo-wrapper, .page-wrapper .page-header {
    background: rgb(3, 86, 90);
    position: fixed;
    width: 100%;
    z-index: 6;
}
.page-wrapper.compact-wrapper .page-header .header-wrapper .logo-wrapper {
    display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 80px);
    margin-top: 80px;
}
.page-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 136px);
    margin-top: 136px;
    padding: 0 15px 0 15px;
    position: relative;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
.page-wrapper .page-body-wrapper .page-title {
    padding-top: 30px;
    padding-bottom: 30px;
}

.page-wrapper .page-body-wrapper .page-title {
    padding-top: 30px;
    padding-bottom: 30px;
}
.card1 {
    margin-bottom: 30px;
    border: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 15px;
    -webkit-box-shadow: 0 0 20px rgb(8 21 66 / 5%);
    box-shadow: 0 0 20px rgb(8 21 66 / 5%);
}

.default-according .card1{
    margin-bottom: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn-primary1 {
    background-color: #03565a !important;
    border-color: #03565a !important;
    color: #fff;
}
button.btn-close {
    border: none;
    background: transparent;
    color: #000;
    font-size: 20px;
}
.ps-5 {
    padding-left: 3rem !important;
}

.pe-5 {
    padding-right: 3rem !important;
}
.float-end {
    float: right;
}
.btn1 {
    padding: 0.375rem 1.75rem;
}
.default-according .card .card-header {
    padding: 0;
    border: none;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.bg-primary1 {
    background-color: #03565a !important;
    color: #fff;
}

.default-according .card .btn-link {
    font-weight: 500;
    color: #2b2b2b;
    text-decoration: none;
}

.default-according .card .card-header.bg-primary .btn, .default-according .card .card-header.bg-secondary .btn {
    border-color: rgba(0, 0, 0, 0);
}
.default-according .card .card-header .btn-link {
    padding: 12px 20px;
    width: 100%;
    text-align: left;
    letter-spacing: 0.7px;
    font-family: Roboto;
    border: 1px solid #efefef;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body {
    margin-left: 15px;
}
.media.profile-media .media-body, .media.profile-media .media-body p.mb-0.font-roboto {
    color: #fff;
}
.media .media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body span {
    font-weight: 500;
}
.b-r-10 {
    border-radius: 10px !important;
}
.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body p {
    font-size: 12px;
    line-height: 1;
    color: #fff;
}
.middle {
    vertical-align: middle;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
    width: 160px;
    top: 57px;
    left: -12px;
    padding: 0 10px;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div {
    width: 330px;
    top: 50px;
    right: 0;
    left: unset;
}
.onhover-show-div {
    -webkit-box-shadow: 0 0 20px rgb(89 102 122 / 10%);
    box-shadow: 0 0 20px rgb(89 102 122 / 10%);
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    left: 0;
}
.onhover-show-div {
    top: 80px;
    position: absolute;
    z-index: 8;
    background-color: #fff;
    -webkit-transition: all linear 0.3s;
    transition: all linear 0.3s;
}

.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li {
    padding: 10px !important;
}
.card1 .card-body1 {
    padding: 40px;
    background-color: rgba(0, 0, 0, 0);
}
.page-wrapper.compact-wrapper .page-body-wrapper .page-body{
    margin-top:30px;
    }
    
    .page-wrapper .page-body-wrapper {
        background-color: #f8f8f8;
    }
    button.btn.btn-primary1{
    color: #fff;
    }
    
    .card1 .card-header {
        background-color: #fff;
        padding: 40px;
        border-bottom: 1px solid #ecf3fa;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        position: relative;
    }
    
    .card1 .card-body {
        padding: 40px;
        background-color: rgba(0, 0, 0, 0);
    }
    
    
    .page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body {
        margin-left: 15px;
    }
    .media .media-body {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .media.profile-media .media-body, .media.profile-media .media-body p.mb-0.font-roboto {
        color: #fff;
    }
    
    .media {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    .onhover-dropdown {
        cursor: pointer;
        position: relative;
    }
    .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
        padding: 6px 10px;
        margin-right: 5px;
        display: inline-block;
        position: relative;
    }
    .page-wrapper .page-header .header-wrapper .nav-right > ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
    .page-wrapper .page-header .header-wrapper .nav-right {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
    .ps-0 {
        padding-left: 0 !important;
    }
    .page-wrapper .page-header .header-wrapper {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0px 30px;
        position: relative;
    }
    ul {
        padding-left: 0px;
        list-style-type: none;
        margin-bottom: 0;
    }
    .nav-right {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
    .page-wrapper .page-body-wrapper .page-title > .row .col-6:first-child {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .page-wrapper .page-header .header-wrapper .nav-right {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
    .page-wrapper .page-header {
        max-width: 100vw;
        position: fixed;
        top: 0;
        z-index: 8;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        /* background-color: #fff; */
        -webkit-box-shadow: 0 0 20px rgb(89 102 122 / 10%);
        box-shadow: 0 0 20px rgb(89 102 122 / 10%);
    }
    
    .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
        padding: 23px 30px;
        -webkit-box-shadow: -9px 0 20px rgb(89 102 122 / 10%);
        box-shadow: -9px 0 20px rgb(89 102 122 / 10%);
    }
    .pull-right { float: right;}
    
    .page-wrapper .page-body-wrapper .page-title .breadcrumb {
        background-color: rgba(0, 0, 0, 0);
        padding: 0;
        margin-bottom: 0;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
    
    
    body.dark-only {
        color: rgba(255, 255, 255, 0.6);
        background-color: #1d1e26;
    }
    body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-title {
        background: #262932;
        color: #fff;
    }
    body.dark-only .card {
        background-color: #262932;
    }
    body.dark-only .ecommerce-widget {
        border: 1px solid #374558 !important;
    }
    body.dark-only .btn-light {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .form-select {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .add-project .text-inherit {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .knowledgebase-search {
        color: #2b2b2b;
    }
    body.dark-only .knowledgebase-search .form-control-plaintext {
        color: #2b2b2b;
    }
    body.dark-only .btn-close {
        -webkit-filter: brightness(0.8) invert(1);
        filter: brightness(0.8) invert(1);
    }
    body.dark-only .prooduct-details-box .media {
        border: 1px solid #374558;
    }
    body.dark-only .product-price del {
        color: #98a6ad;
    }
    body.dark-only .ProfileCard {
        border: 1px solid #374558 !important;
    }
    body.dark-only .form-control-plaintext {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .form-select {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .box-layout {
        background-color: #1d1e26;
    }
    body.dark-only .box-layout .page-wrapper .page-header .header-wrapper,
    body.dark-only .box-layout.page-wrapper .page-header .header-wrapper {
        border: 1px solid #1d1e26;
    }
    body.dark-only .form-check-input {
        background-color: #1d1e26;
    }
    body.dark-only .switch-state:before {
        background-color: #262932;
    }
    body.dark-only .daterangepicker:before {
        border-bottom-color: #262932;
    }
    body.dark-only .daterangepicker .ranges li {
        background-color: #1d1e26;
        border-color: #262932;
        color: #898989;
    }
    body.dark-only .daterangepicker .daterangepicker_input .form-control {
        background-color: #1d1e26;
        border-color: #262932 !important;
    }
    body.dark-only .daterangepicker .calendar-time select {
        border-color: #262932;
        background-color: #1d1e26;
    }
    body.dark-only .daterangepicker.dropdown-menu {
        background-color: #262932;
        -webkit-box-shadow: 0 0 14px #1d1e26;
        box-shadow: 0 0 14px #1d1e26;
    }
    body.dark-only .daterangepicker .calendar-table {
        background-color: #262932;
        border-color: #262932;
    }
    body.dark-only .daterangepicker.ltr td.in-range,
    body.dark-only .daterangepicker.ltr td.off {
        background-color: #1d1e26;
        color: #fff;
    }
    body.dark-only .tooltip.bs-tooltip-top .tooltip-arrow:before {
        border-top-color: #323846;
    }
    body.dark-only .tooltip.bs-tooltip-bottom .tooltip-arrow:before {
        border-bottom-color: #323846;
        border-top-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .tooltip.bs-tooltip-start .tooltip-arrow:before {
        border-left-color: #323846;
        border-top-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .tooltip.bs-tooltip-end .tooltip-arrow:before {
        border-right-color: #323846;
        border-top-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .tooltip .tooltip-inner {
        background-color: #323846;
        color: #fff;
    }
    body.dark-only .tooltip .tooltip-arrow:before {
        border-top-color: #323846;
    }
    body.dark-only .page-wrapper.only-body .page-body-wrapper .page-title > .row .col-6:first-child h6 {
        color: rgba(255, 255, 255, 0.5);
    }
    body.dark-only .page-wrapper.only-body .page-header .header-wrapper .search-full .form-group .Typeahead .u-posRelative {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li span {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back {
        background-color: #262932 !important;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back .flip-back-content input {
        background-color: #1d1e26;
        border: 1px solid #374558;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back li:last-child {
        border-top: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front {
        background-color: #1d1e26 !important;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front svg {
        stroke: #fff;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div:not(.profile-dropdown) li:first-child {
        background-color: #262932;
    }
    body.dark-only .customizer-contain {
        color: #1d1e26;
    }
    body.dark-only .customizer-contain .customizer-body .main-layout .box-layout {
        background-color: #fff;
    }
    body.dark-only .login-card .btn-showcase .btn {
        background-color: #262932 !important;
        color: #fff;
        border-color: #262932 !important;
    }
    body.dark-only .login-card .login-main {
        -webkit-box-shadow: 0 0 37px rgba(255, 255, 255, 0.05);
        box-shadow: 0 0 37px rgba(255, 255, 255, 0.05);
    }
    body.dark-only .login-card .login-main .theme-form input {
        background-color: #262932 !important;
    }
    body.dark-only .login-card .login-main .theme-form .or:before {
        background-color: #262932;
    }
    body.dark-only .login-card .login-main .theme-form .checkbox label::before {
        background-color: #262932 !important;
        border-color: #262932 !important;
    }
    body.dark-only .H_ui ~ div {
        background-color: #1d1e26 !important;
    }
    body.dark-only .swal-icon--success:after,
    body.dark-only .swal-icon--success:before {
        background: #262932;
    }
    body.dark-only .swal-icon--success__hide-corners {
        background-color: #262932;
    }
    body.dark-only .note-editor.note-frame .note-status-output {
        border-top: 1px solid #262932;
    }
    body.dark-only .note-editor.note-frame .note-statusbar {
        border-top: 1px solid #262932;
    }
    body.dark-only .note-editor.note-frame .note-statusbar .note-resizebar {
        background-color: #262932;
    }
    body.dark-only .light-font {
        color: #fff;
    }
    body.dark-only .page-link {
        background-color: #1d1e26;
        border: 1px solid #374558;
    }
    body.dark-only .b-r-light {
        border-right: 1px solid #374558 !important;
    }
    body.dark-only .history-details .media {
        border-top: 1px solid #374558;
    }
    body.dark-only .modal-header {
        border-bottom: 1px solid #374558;
    }
    body.dark-only #right-history {
        background-color: #262932;
        -webkit-box-shadow: 0 0 9px #1d1e26;
        box-shadow: 0 0 9px #1d1e26;
    }
    body.dark-only #right-history h6 span a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .ProfileCard:hover {
        color: rgba(255, 255, 255, 0.6);
        background-color: #262932;
    }
    body.dark-only .translate_wrapper.active .more_lang:before {
        border-bottom: 7px solid #262932;
    }
    body.dark-only .translate_wrapper.active .more_lang .lang {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .toggle-sidebar svg {
        stroke: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .sidebar-main-title p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
        background-color: #262932;
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-link.active svg {
        stroke: var(--theme-deafult);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
        stroke: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
        background-color: rgba(115, 102, 255, 0.2);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
        color: var(--theme-deafult);
        stroke: var(--theme-deafult);
    }
    body.dark-only
        .page-wrapper.compact-wrapper
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-title
        h5 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only
        .page-wrapper.compact-wrapper
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-content.opensubmegamenu
        li
        a {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 400;
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
        background-color: rgba(255, 255, 255, 0.2);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
        background: #262932;
        border-top: 1px solid #374558;
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
        background: #262932;
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active,
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .nav-sub-childmenu {
        background: #262932;
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
        background: #262932;
    }
    body.dark-only
        .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-content
        li
        a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow,
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
        background: #262932;
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-title {
        border-bottom: 1px solid #374558;
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
        stroke: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
        background-color: #262932;
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
        border-bottom: 1px solid #374558;
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
        background-color: rgba(115, 102, 255, 0.2);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active span {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
        color: var(--theme-deafult);
        stroke: var(--theme-deafult);
    }
    body.dark-only
        .page-wrapper.compact-sidebar
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-title
        h5 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only
        .page-wrapper.compact-sidebar
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-content.opensubmegamenu
        li
        a {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 400;
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
        background-color: rgba(255, 255, 255, 0.2);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper .according-menu i {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.material-type .page-header .header-wrapper {
        border-radius: 15px 15px 0 0;
    }
    body.dark-only .page-wrapper.material-icon .page-header .header-wrapper {
        background-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper.material-icon .page-header .header-wrapper .nav-right .language-nav .more_lang {
        color: #fff;
    }
    body.dark-only .page-wrapper.material-icon .page-header .header-wrapper .nav-right .onhover-show-div {
        color: #fff;
    }
    body.dark-only .page-wrapper.material-icon .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back .flip-back-content input {
        border: 1px solid #1d1e26;
        background: #1d1e26;
        color: #fff;
    }
    body.dark-only .page-wrapper.material-icon .page-header .header-logo-wrapper {
        background-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper.material-icon .page-body-wrapper .page-body {
        background-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper.advance-layout {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper.advance-layout .page-header .header-wrapper {
        background: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper.advance-layout .page-header .header-wrapper .header-logo-wrapper {
        background: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper.advance-layout .page-body-wrapper {
        background: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper.advance-layout .page-body-wrapper .page-body {
        background: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .search-full {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .search-full input {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only ::-webkit-calendar-picker-indicator {
        -webkit-filter: invert(1);
        filter: invert(1);
    }
    body.dark-only .left-header .mega-menu-container {
        background-color: #262932;
        border-top: 1px solid #262932;
    }
    body.dark-only .left-header .mega-menu-container .mega-box + .mega-box {
        border-left: 1px solid #374558;
    }
    body.dark-only .left-header .mega-menu div > div a {
        color: rgba(255, 255, 255, 0.7);
    }
    body.dark-only .left-header .level-menu .header-level-menu {
        background-color: #262932;
    }
    body.dark-only .left-header .level-menu .header-level-menu > li .header-level-sub-menu {
        background-color: #262932;
    }
    body.dark-only .left-header .level-menu .header-level-menu > li a {
        color: rgba(255, 255, 255, 0.7);
    }
    body.dark-only .left-header .level-menu .header-level-menu > li svg {
        stroke: rgba(255, 255, 255, 0.7);
    }
    body.dark-only .kanban-board-header {
        background-color: #1d1e26 !important;
        border-bottom: 1px solid #262932 !important;
    }
    body.dark-only .kanban-board .kanban-drag {
        background: #1d1e26 !important;
    }
    body.dark-only .kanban-container .kanban-item .kanban-box {
        border: 1px solid rgba(115, 102, 255, 0.15);
        background-color: #1d1e26;
    }
    body.dark-only .project-box {
        border: 1px solid rgba(115, 102, 255, 0.15);
        background-color: #1d1e26;
    }
    body.dark-only .file-content .form-inline {
        border: 1px solid #1d1e26;
    }
    body.dark-only .file-content .files .file-box {
        border: 1px solid rgba(115, 102, 255, 0.15);
        background-color: #1d1e26;
    }
    body.dark-only .file-content .files .file-box .file-top {
        background-color: #262932;
        border: 1px solid rgba(115, 102, 255, 0.15);
    }
    body.dark-only .file-content .folder .folder-box {
        border: 1px solid rgba(115, 102, 255, 0.15);
        background-color: #1d1e26;
    }
    body.dark-only .file-sidebar .pricing-plan {
        border: 1px solid rgba(115, 102, 255, 0.15) !important;
    }
    body.dark-only .file-sidebar .btn-light {
        color: rgba(255, 255, 255, 0.6);
        background-color: #1d1e26 !important;
        border: 1px solid rgba(115, 102, 255, 0.15) !important;
    }
    body.dark-only #lnb {
        border-right: 1px solid #374558;
    }
    body.dark-only .lnb-new-schedule,
    body.dark-only .lnb-calendars > div {
        border-bottom: 1px solid #374558;
    }
    body.dark-only #menu .sidebar-list .btn-default {
        color: rgba(255, 255, 255, 0.2);
    }
    body.dark-only .tui-full-calendar-timegrid-hour {
        background-color: #262932;
        color: #fff !important;
    }
    body.dark-only .tui-full-calendar-timegrid-gridline {
        border-bottom: 1px solid #374558 !important;
    }
    body.dark-only .tui-full-calendar-time-date,
    body.dark-only .tui-full-calendar-weekday-grid-line,
    body.dark-only .tui-full-calendar-left,
    body.dark-only .tui-full-calendar-timegrid-timezone {
        border-right-color: #374558 !important;
    }
    body.dark-only .tui-full-calendar-popup {
        color: #2b2b2b;
    }
    body.dark-only #menu .btn-default {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only #menu .btn-default:hover {
        color: #2b2b2b;
    }
    body.dark-only #menu .dropdown-menu {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .tui-full-calendar-dayname-container,
    body.dark-only .tui-full-calendar-splitter {
        border-top-color: #374558 !important;
        border-bottom-color: #374558 !important;
    }
    body.dark-only span.tui-full-calendar-dayname-date-area {
        color: #fff !important;
    }
    body.dark-only .tui-full-calendar-layout {
        background-color: #262932 !important;
    }
    body.dark-only .chat-default .card-body.chat-box .right-side-chat .media-body .message-main span {
        background-color: var(--theme-deafult);
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .chat-default .card-body.chat-box .right-side-chat .media-body .message-main span.loader-span .dot {
        background: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .blockquote {
        border-left: 4px solid #374558;
    }
    body.dark-only .figure.text-end blockquote {
        border-right-color: #374558;
    }
    body.dark-only .contacts-tabs .nav-pills {
        border-right: 1px solid #374558;
    }
    body.dark-only .contacts-tabs .nav-pills .nav-link + .nav-link {
        border-top: 1px solid #374558;
    }
    body.dark-only .list-persons .profile-mail .media .media-body ul li + li {
        border-left: 2px solid #374558;
    }
    body.dark-only .product-wrapper.sidebaron .product-sidebar .filter-section .card .left-filter {
        background-color: #262932;
    }
    body.dark-only .apexcharts-gridline {
        stroke: #1d1e26;
    }
    body.dark-only .apexcharts-tooltip-title,
    body.dark-only .apexcharts-tooltip-series-group,
    body.dark-only .apexcharts-tooltip.light,
    body.dark-only .apexcharts-menu-item {
        color: #000;
    }
    body.dark-only .small-donut ~ svg path:nth-child(even),
    body.dark-only .small-donut svg path:nth-child(even),
    body.dark-only .donut-peity2 ~ svg path:nth-child(even),
    body.dark-only .donut-peity2 svg path:nth-child(even),
    body.dark-only .pie-peity ~ svg path:nth-child(even),
    body.dark-only .pie-peity svg path:nth-child(even) {
        fill: #1d1e26;
    }
    body.dark-only .earning-card.card .card-body .left_side_earning p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .earning-card.card .card-body .earning-content {
        border-right: 1px solid #374558;
    }
    body.dark-only .earning-card.card .card-body .inner-top-left ul li,
    body.dark-only .earning-card.card .card-body .inner-top-right ul li {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .earning-card.card .card-body .inner-top-left ul li.active,
    body.dark-only .earning-card.card .card-body .inner-top-right ul li.active {
        color: var(--theme-deafult);
    }
    body.dark-only .earning-card.card .card-body .media .media-body p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .earning-card.card .card-body .border-top {
        border-top: 1px solid #374558 !important;
    }
    body.dark-only .chart_data_left .card-body .chart-main .media,
    body.dark-only .chart_data_right .card-body .chart-main .media {
        border-right: 1px solid #374558;
    }
    body.dark-only .chart_data_left .card-body .chart-main .media.border-none,
    body.dark-only .chart_data_right .card-body .chart-main .media.border-none {
        border-right: none;
    }
    body.dark-only .chart_data_left .card-body .media .right-chart-content span,
    body.dark-only .chart_data_right .card-body .media .right-chart-content span {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .chart_data_right .card-body .knob-block input {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .third-news-update .news-update,
    body.dark-only .news .news-update,
    body.dark-only .appointment-sec .news-update {
        border-bottom: 1px solid #374558;
    }
    body.dark-only .third-news-update .news-update span,
    body.dark-only .news .news-update span,
    body.dark-only .appointment-sec .news-update span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .third-news-update .news-update h6,
    body.dark-only .news .news-update h6,
    body.dark-only .appointment-sec .news-update h6 {
        margin-bottom: 2px;
        line-height: 24px;
    }
    body.dark-only .third-news-update .appointment .radar-chart .apexcharts-datalabel,
    body.dark-only .news .appointment .radar-chart .apexcharts-datalabel,
    body.dark-only .appointment-sec .appointment .radar-chart .apexcharts-datalabel {
        fill: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .chat-default .card-body.chat-box .input-group .form-control {
        background-color: #262932 !important;
        border: none !important;
        border-left: 1px solid var(--theme-deafult) !important;
    }
    body.dark-only .chat-default .card-body.chat-box .input-group .form-control::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .chat-default .card-body.chat-box .input-group .form-control::-moz-placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .chat-default .card-body.chat-box .input-group .form-control:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .chat-default .card-body.chat-box .input-group .form-control::-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .chat-default .card-body.chat-box .input-group .form-control::placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .appointment-table table tr td.img-content-box .font-roboto {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .notification .card .media .media-body p span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .notification .card .media .media-body span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .datepicker {
        background-color: #323846;
    }
    body.dark-only .datepicker .datepicker--cell.-current- {
        color: #03565a;
    }
    body.dark-only .cal-date-widget .datepicker {
        background-color: #323846;
    }
    body.dark-only .cal-date-widget .datepicker .datepicker--cell-day {
        color: #fff;
    }
    body.dark-only .cal-date-widget .datepicker .datepicker--cell.-current- {
        color: #03565a;
    }
    body.dark-only .default-datepicker .datepicker-inline .datepicker {
        background: #262932;
    }
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title i {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--day-name,
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--cell-day,
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--day-name,
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell-day {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--day-name.-selected-,
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--cell-day.-selected-,
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--day-name.-selected-,
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell-day.-selected- {
        color: #fff;
    }
    body.dark-only .dash-chart .media .media-body p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .dash-chart .media .media-body p .font-roboto {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .our-product table tr td p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .activity-timeline .media .activity-line {
        background-color: rgba(255, 255, 255, 0.2);
    }
    body.dark-only .activity-timeline .media .media-body p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .total-users .goal-status ul:last-child li {
        border-top-color: #374558;
    }
    body.dark-only .best-seller-table table tbody tr td .label {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .best-seller-table table tbody tr td p {
        color: rgba(255, 255, 255, 0.2);
    }
    body.dark-only .best-seller-table table tbody tr:hover td {
        color: var(--theme-deafult) !important;
    }
    body.dark-only .best-seller-table table tbody tr:hover td span {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .best-seller-table table tbody tr:hover td .label,
    body.dark-only .best-seller-table table tbody tr:hover td p {
        color: var(--theme-deafult) !important;
    }
    body.dark-only [class*="activity-dot-"] {
        background-color: #262932;
    }
    body.dark-only .page-header .header-wrapper .nav-right .chat-dropdown li:last-child,
    body.dark-only .page-header .header-wrapper .nav-right .profile-dropdown li:last-child {
        border-top: 1px solid #374558 !important;
    }
    body.dark-only .alert-primary {
        background-color: rgba(115, 102, 255, 0.8) !important;
        border-color: rgba(115, 102, 255, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-primary .progress {
        height: 5px;
        background-color: #6e61ff;
        border-radius: 0;
    }
    body.dark-only .alert-primary .progress-bar {
        background-color: #fff;
    }
    body.dark-only .alert-primary.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-primary.inverse .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-primary.outline .btn-close,
    body.dark-only .alert-primary.outline-2x .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        -webkit-filter: unset;
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .alert-secondary {
        background-color: rgba(247, 49, 100, 0.8) !important;
        border-color: rgba(247, 49, 100, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-secondary .progress {
        height: 5px;
        background-color: #f72c60;
        border-radius: 0;
    }
    body.dark-only .alert-secondary .progress-bar {
        background-color: #fff;
    }
    body.dark-only .alert-secondary.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-secondary.inverse .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-secondary.outline .btn-close,
    body.dark-only .alert-secondary.outline-2x .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        -webkit-filter: unset;
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .alert-success {
        background-color: rgba(81, 187, 37, 0.8) !important;
        border-color: rgba(81, 187, 37, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-success .progress {
        height: 5px;
        background-color: #4fb724;
        border-radius: 0;
    }
    body.dark-only .alert-success .progress-bar {
        background-color: #ebfae5;
    }
    body.dark-only .alert-success.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-success.inverse .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-success.outline .btn-close,
    body.dark-only .alert-success.outline-2x .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        -webkit-filter: unset;
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .alert-danger {
        background-color: rgba(220, 53, 69, 0.8) !important;
        border-color: rgba(220, 53, 69, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-danger .progress {
        height: 5px;
        background-color: #db3141;
        border-radius: 0;
    }
    body.dark-only .alert-danger .progress-bar {
        background-color: #fff;
    }
    body.dark-only .alert-danger.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-danger.inverse .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-danger.outline .btn-close,
    body.dark-only .alert-danger.outline-2x .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        -webkit-filter: unset;
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .alert-warning {
        background-color: rgba(248, 214, 43, 0.8) !important;
        border-color: rgba(248, 214, 43, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-warning .progress {
        height: 5px;
        background-color: #f8d526;
        border-radius: 0;
    }
    body.dark-only .alert-warning .progress-bar {
        background-color: #fff;
    }
    body.dark-only .alert-warning.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-warning.inverse .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-warning.outline .btn-close,
    body.dark-only .alert-warning.outline-2x .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        -webkit-filter: unset;
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .alert-info {
        background-color: rgba(169, 39, 249, 0.8) !important;
        border-color: rgba(169, 39, 249, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-info .progress {
        height: 5px;
        background-color: #a722f9;
        border-radius: 0;
    }
    body.dark-only .alert-info .progress-bar {
        background-color: #fff;
    }
    body.dark-only .alert-info.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-info.inverse .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-info.outline .btn-close,
    body.dark-only .alert-info.outline-2x .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        -webkit-filter: unset;
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .alert-light {
        background-color: rgba(244, 244, 244, 0.8) !important;
        border-color: rgba(244, 244, 244, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-light .progress {
        height: 5px;
        background-color: #f1f1f1;
        border-radius: 0;
    }
    body.dark-only .alert-light .progress-bar {
        background-color: #fff;
    }
    body.dark-only .alert-light.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-light.inverse .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-light.outline .btn-close,
    body.dark-only .alert-light.outline-2x .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        -webkit-filter: unset;
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .alert-dark {
        background-color: rgba(44, 50, 63, 0.8) !important;
        border-color: rgba(44, 50, 63, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-dark .progress {
        height: 5px;
        background-color: #2a303c;
        border-radius: 0;
    }
    body.dark-only .alert-dark .progress-bar {
        background-color: #a8b0c2;
    }
    body.dark-only .alert-dark.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-dark.inverse .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-dark.outline .btn-close,
    body.dark-only .alert-dark.outline-2x .btn-close {
        -webkit-filter: brightness(1) invert(1);
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        -webkit-filter: unset;
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .options > div {
        border: 1px solid #374558;
    }
    body.dark-only .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
        background-color: #1d1e26;
    }
    body.dark-only .pricing-simple {
        -webkit-box-shadow: 1px 1px 2px 1px #1d1e26 !important;
        box-shadow: 1px 1px 2px 1px #1d1e26 !important;
    }
    body.dark-only .pricing-block {
        -webkit-box-shadow: 0 0 10px 5px rgba(115, 102, 255, 0.05) !important;
        box-shadow: 0 0 10px 5px rgba(115, 102, 255, 0.05) !important;
    }
    body.dark-only .search-page ul.search-info li + li {
        border-left: 1px solid #1d1e26;
    }
    body.dark-only .browse .browse-articles h6 {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .job-search .job-description .theme-form .row div[class^="col-"] .input-group .datepicker-here {
        border: 1px solid #1d1e26;
    }
    body.dark-only .calendar-wrap .fc-unthemed td,
    body.dark-only .calendar-wrap .fc-unthemed th {
        border-color: #1d1e26;
    }
    body.dark-only .mega-inline .card,
    body.dark-only .mega-horizontal .card,
    body.dark-only .mega-vertical .card {
        -webkit-box-shadow: 1px 1px 7px 0 rgba(55, 69, 88, 0.5) !important;
        box-shadow: 1px 1px 7px 0 rgba(55, 69, 88, 0.5) !important;
    }
    body.dark-only .mega-inline.plain-style .card,
    body.dark-only .mega-inline.border-style .card,
    body.dark-only .mega-inline.offer-style .card,
    body.dark-only .mega-horizontal.plain-style .card,
    body.dark-only .mega-horizontal.border-style .card,
    body.dark-only .mega-horizontal.offer-style .card,
    body.dark-only .mega-vertical.plain-style .card,
    body.dark-only .mega-vertical.border-style .card,
    body.dark-only .mega-vertical.offer-style .card {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    body.dark-only .mega-inline.border-style .card,
    body.dark-only .mega-horizontal.border-style .card,
    body.dark-only .mega-vertical.border-style .card {
        border: 1px solid #374558 !important;
    }
    body.dark-only .mega-inline.offer-style .card,
    body.dark-only .mega-horizontal.offer-style .card,
    body.dark-only .mega-vertical.offer-style .card {
        border: 1px dashed #374558 !important;
    }
    body.dark-only .setting-dot .setting-bg {
        background-color: var(--theme-secondary);
    }
    body.dark-only .bookmark.pull-right {
        border: none;
    }
    body.dark-only .popover {
        background-color: #1d1e26;
    }
    body.dark-only .popover.bs-popover-bottom .popover-arrow:after {
        border-bottom-color: #1d1e26;
        border-right-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .popover.bs-popover-top .popover-arrow:after {
        border-right-color: rgba(0, 0, 0, 0);
        border-top-color: #1d1e26;
    }
    body.dark-only .popover.bs-popover-start .popover-arrow:after {
        border-left-color: #1d1e26;
    }
    body.dark-only .popover .popover-header {
        background-color: #262932;
    }
    body.dark-only .popover .popover-arrow:after {
        border-right-color: #1d1e26;
    }
    body.dark-only .popover .popover-body {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .popover code {
        background-color: unset;
    }
    body.dark-only .apex-chart-container .apexcharts-legend .apexcharts-legend-series span {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .apexcharts-canvas svg .apexcharts-title-text {
        fill: #fff;
    }
    body.dark-only .apexcharts-canvas svg .apexcharts-subtitle-text {
        fill: #fff;
    }
    body.dark-only .apexcharts-canvas svg .apexcharts-yaxis .apexcharts-yaxis-texts-g .apexcharts-yaxis-label {
        fill: #fff;
    }
    body.dark-only .apexcharts-canvas svg .apexcharts-xaxis .apexcharts-xaxis-texts-g .apexcharts-xaxis-label {
        fill: #fff;
    }
    body.dark-only .apexcharts-canvas svg .apexcharts-legend-series span {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .apexcharts-canvas svg .apexcharts-datalabels-group .apexcharts-datalabel-label {
        fill: #fff;
    }
    body.dark-only .apexcharts-canvas svg .apexcharts-datalabels-group .apexcharts-datalabel-value {
        fill: #fff;
    }
    body.dark-only .Typeahead-menu {
        background-color: #1d1e26;
    }
    body.dark-only .ecommerce-widget {
        border: 1px solid #1d1e26;
    }
    body.dark-only .bookmark ul li .search-form .form-control-search input {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .bookmark ul li .search-form .form-control-search:before {
        background: #1d1e26;
    }
    body.dark-only .cart .qty-box .input-group .btn {
        background-color: #2e3648 !important;
        border-color: #374558 !important;
    }
    body.dark-only .checkout .checkout-details {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        padding: 40px;
    }
    body.dark-only .checkout .checkout-details .animate-chk .radio_animated:after {
        border: 2px solid #262932;
    }
    body.dark-only .order-box .title-box {
        color: #bfc2c6;
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .order-box .qty {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .order-box .qty li {
        color: #bfc2c6;
    }
    body.dark-only .order-box .qty li span {
        color: #bfc2c6;
    }
    body.dark-only .order-box .sub-total li {
        color: #bfc2c6;
    }
    body.dark-only .order-box .total li {
        color: #bfc2c6;
    }
    body.dark-only .shopping-checkout-option .checkbox_animated:after {
        border: 2px solid #262932;
    }
    body.dark-only .animate-chk .radio_animated:after {
        border: 2px solid #1d1e26;
    }
    body.dark-only .custom-scrollbar::-webkit-scrollbar-thumb {
        background-color: rgba(41, 50, 64, 0.52);
    }
    body.dark-only .product-box .product-img .product-hover ul li .btn {
        color: #98a6ad;
    }
    body.dark-only .product-box .product-img .product-hover ul li:hover .btn {
        color: #fff;
    }
    body.dark-only .radio_animated:after,
    body.dark-only .checkbox_animated:after {
        background: #1d1e26;
        border: 2px solid #1d1e26;
    }
    body.dark-only .slider-product {
        border-top: 1px solid #1d1e26;
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .square-product-setting .icon-grid {
        background-color: #262932;
    }
    body.dark-only .square-product-setting .icon-grid svg {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .active-order-table table thead tr th,
    body.dark-only .market-table table thead tr th {
        border-top: none !important;
    }
    body.dark-only .active-order-table table tbody tr td,
    body.dark-only .market-table table tbody tr td {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .active-order-table table tbody tr:last-child td,
    body.dark-only .market-table table tbody tr:last-child td {
        border-bottom: none;
    }
    body.dark-only .pricing-card-design-2 .pricing-block .pricing-inner ul li {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .flot-chart-container .legend .table tbody {
        background-color: #262932;
        color: #fff;
    }
    body.dark-only .flot-chart-container .legend .table tbody .legendLabel {
        padding-left: 5px;
    }
    body.dark-only .google-visualization-tooltip text {
        fill: rgba(0, 0, 0, 0.7) !important;
    }
    body.dark-only .nav-dark .nav-link,
    body.dark-only .nav-pills.nav-dark .nav-link {
        color: #fff;
    }
    body.dark-only .loader-wrapper {
        background-color: #1d1e26;
    }
    body.dark-only .loader-wrapper .loader {
        background-color: #1d1e26 !important;
    }
    body.dark-only .page-wrapper .page-header .translate_wrapper.active .more_lang {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-header .translate_wrapper.active .lang {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-header form {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper li i {
        color: #f4f4f4;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li svg {
        stroke: #fff;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right.right-header > ul > li .profile-media .media-body p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .media .media-body .txt-dark {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div {
        background-color: #1d1e26;
        -webkit-box-shadow: 0 0 14px #1d1e26;
        box-shadow: 0 0 14px #1d1e26;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div li {
        border-color: #262932;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div:before {
        border-bottom: 7px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div:after {
        border-bottom: 7px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .notification-dropdown.onhover-show-div li span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .notification-dropdown.onhover-show-div .bg-light {
        background-color: #282e38 !important;
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a svg path,
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a svg line,
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a svg circle {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover svg line,
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover svg path,
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover svg circle {
        color: var(--theme-deafult) !important;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .media .media-body .txt-dark {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .onhover-show-div {
        background-color: #1d1e26;
        -webkit-box-shadow: 0 0 2px 2px #262932;
        box-shadow: 0 0 2px 2px #262932;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .onhover-show-div:before {
        border-bottom: 7px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .onhover-show-div:after {
        border-bottom: 7px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .notification-dropdown.onhover-show-div li {
        border-color: #262932;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .notification-dropdown.onhover-show-div li span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .chat-dropdown li {
        color: #fff;
        border-color: #262932;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .left-header ul li .search-form input::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .left-header ul li .search-form input::-moz-placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .left-header ul li .search-form input:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .left-header ul li .search-form input::-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .left-header ul li .search-form input::placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-header .header-logo-wrapper {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-header .header-logo-wrapper .logo-wrapper a .image-dark {
        display: none;
    }
    body.dark-only .page-wrapper .page-header .header-logo-wrapper .logo-wrapper a .image-light {
        display: block;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .topper-lists table tbody tr td p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .header-small {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .statistics p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .feather-main .media-body p,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .feather-main .professor-block p,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .professor-table .media-body p,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .professor-table .professor-block p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .logs-element span + span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .progress-block .progress-title span + span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .ecommerce-icons div span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .new-users .media .media-body p,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .recent-notification .media .media-body p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .progress-media .media .media-body span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .progress-media .progress-change span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .topper-lists table tbody tr td {
        border-top: none !important;
        border-bottom: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .topper-lists table tbody tr:last-child td {
        border-bottom: none;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .notifiaction-media .media .media-body .circle-left {
        border: 5px solid #334154;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .upcoming-event .upcoming-innner {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .upcoming-event .upcoming-innner:last-child {
        border-bottom: none;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .professor-table table tbody tr td {
        border-top: none !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .number-widgets .media .media-body h6 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .media .gradient-round.gradient-line-1:after,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .media .gradient-round.small-line:after,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .media .gradient-round.medium-line:after {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .media .media-body h6 span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card.custom-card .card-header {
        border-bottom: none !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .custom-card .card-header img {
        background-color: #000;
        opacity: 0.8;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-title .row h3 small {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-title .breadcrumb li {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-title .breadcrumb .breadcrumb-item a svg {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-title .breadcrumb-item.active {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card:not(.email-body) {
        border: none;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) {
        background-color: #262932;
        -webkit-box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
        box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block #bar-chart2 svg > rect {
        fill: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block #bar-chart2 svg > g text {
        fill: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block .word-tree svg > g > rect {
        fill: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block .word-tree svg > g > text {
        fill: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header > span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h2,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h3,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h4,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h5,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h6 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header .card-header-right {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header .card-header-right i {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header .card-header-right i.fa-cog {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-dark {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-dark a {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-light.outline,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-light.outline-2x,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-light.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #animation-box .animate-widget p {
        color: #98a6ad !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .grid-item img {
        background-color: #262932;
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .line {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) thead,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) tbody,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) tfoot,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) tr,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) th {
        border-color: #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table td {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table thead th {
        border-bottom: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table thead .border-bottom-primary th {
        border-bottom: 1px solid var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-primary th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-primary td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-secondary th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-secondary td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-success th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-success td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-info th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-info td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-warning th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-warning td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-light th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-light td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-danger th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-danger td {
        color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-primary,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-secondary,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-success,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-info,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-warning,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-danger {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-light {
        color: #000;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .thead-light th {
        color: #000;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table tbody .border-bottom-primary th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table tbody .border-bottom-primary td {
        border-bottom: 1px solid var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table[class*="bg-"] th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table[class*="bg-"] td {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-striped tbody tr:nth-of-type(odd) {
        --bs-table-accent-bg: rgba(0, 0, 0, 0.05);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-striped tbody tr:nth-of-type(odd):hover th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-striped tbody tr:nth-of-type(odd):hover td {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-double,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-dotted,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-dashed {
        border-left-color: #374558;
        border-right-color: #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-bordered {
        border-color: #374558 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-bordered td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-bordered th {
        border-color: #374558 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-inverse tbody tr:nth-of-type(odd):hover td {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-border-vertical tr,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-border-vertical th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-border-vertical td {
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling thead th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling thead td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling tbody th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling tbody td {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-footer {
        background-color: #262932;
        border-top: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .switch .switch-state {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .switch input:checked + .switch-state {
        background-color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .bg-white {
        background-color: #fff !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .b-l-light {
        border-left: 1px solid #1d1e26 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ct-grid {
        stroke: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ct-label {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) hr {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .text-muted {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .calender-widget .cal-date {
        background-color: #262932;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .contact-form .theme-form {
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .contact-form .theme-form .form-icon {
        background-color: #262932;
        border: 1px solid #374558;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-light,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-dark,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-light-2x {
        color: #fff !important;
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-light:hover {
        color: #000 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-right {
        border-right: 1px solid #1d1e26 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .flot-chart-placeholder .flot-text {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .flot-chart-placeholder svg text {
        fill: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#gantt_chart svg g rect:first-child {
        fill: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#gantt_chart rect:nth-child(6) {
        fill: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#line-chart rect,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#column-chart1 rect,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#column-chart2 rect {
        fill: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow svg > rect {
        fill: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow svg > g > g > g text {
        fill: #b1b1b2;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow svg > g > text {
        fill: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .radial-bar:after {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .bar-chart-widget .bottom-content .num {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .bar-chart-widget .bottom-content .num .color-bottom {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .b-r-light {
        border-right: 1px solid #374558 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .live-products .ct-labels .ct-label,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .turnover .ct-labels .ct-label,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .monthly .ct-labels .ct-label,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .uses .ct-labels .ct-label {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .live-products .ct-grid,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .turnover .ct-grid,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .monthly .ct-grid,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .uses .ct-grid {
        stroke: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container #browser-uses-chart svg text,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container #website-visiter-chart svg text {
        fill: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .status-details h4 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .status-details h4 span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .status-details span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .clipboaard-container .form-control {
        background-color: #1d1e26;
        color: #98a6ad;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .img-thumbnail {
        background-color: #1d1e26;
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper button {
        color: #000;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper.no-footer .dataTables_scrollBody {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .btn-danger,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .btn-success,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .btn-primary {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_length select {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_length,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_filter,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_info,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_processing,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_filter input[type="search"] {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr:hover > .sorting_1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr:hover > .sorting_1 {
        background-color: #1c212b;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.odd,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr.odd {
        background-color: #1f232b;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.odd > .sorting_1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
        background-color: #1c212b;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.even > .sorting_1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
        background-color: #22262f;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable thead th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable thead td {
        border-bottom: 2px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable input,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable select {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable tbody tr {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable tbody td.select-checkbox:before,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable tbody th.select-checkbox:before {
        border: 1px solid #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .table-striped tbody tr:nth-of-type(odd) {
        --bs-table-accent-bg: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.current,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button:active {
        border-color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
        color: #98a6ad !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.disabled :active {
        color: #98a6ad !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.row-border tbody th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.row-border tbody td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody td {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.even:hover > .sorting_1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.hover tbody tr.even:hover > .sorting_1 {
        background-color: #191e27;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.hover tbody tr:hover,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr:hover {
        background-color: #1c212b;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border td {
        border-top: 1px solid #1d1e26;
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border th:first-child,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border td:first-child {
        border-left: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column tbody tr > .sorting_1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column tbody tr > .sorting_2,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column tbody tr > .sorting_3,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr > .sorting_1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr > .sorting_2,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr > .sorting_3 {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #example-style-3_wrapper #example-style-3 tfoot {
        border-top: 2px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-link {
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-item.disabled .page-link {
        background-color: #262932;
        border-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-link {
        color: rgba(255, 255, 255, 0.6);
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-item:hover .page-link {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-item.active .page-link {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget .icon {
        color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget .total-num {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget .total-num span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .flot-chart-container-small {
        background-color: #262932;
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .product-table #API-2_wrapper #API-2 tbody td span,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .product-table #API-2_wrapper #API-2 tbody td p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .product-table h6 {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-item .nav-link {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-link.active,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-link:focus,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-link:hover {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-1to10 .br-widget a,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-movie .br-widget a,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-pill .br-widget a,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-reversed .br-widget a,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-horizontal .br-widget a {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-1to10 .br-widget a.br-active,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-1to10 .br-widget a.br-selected,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-movie .br-widget a.br-active,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-movie .br-widget a.br-selected,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-pill .br-widget a.br-active,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-pill .br-widget a.br-selected,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-reversed .br-widget a.br-active,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-reversed .br-widget a.br-selected,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-horizontal .br-widget a.br-active,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-horizontal .br-widget a.br-selected {
        background-color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-square .br-widget a {
        border: 2px solid #1d1e26;
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-square .br-widget a.br-active,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-square .br-widget a.br-selected {
        border: 2px solid var(--theme-deafult);
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars .br-widget a.br-selected:after,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars .br-widget a.br-active:after,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars-o .br-widget a.br-selected:after,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars-o .br-widget a.br-active:after {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .scroll-demo {
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .search-form input {
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .search-form .form-group:before {
        background: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .cd-timeline-content {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .cd-timeline-content::before {
        border-left: 7px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
        border-right-color: #1d1e26;
        border-left: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .breadcrumb.bg-white {
        background-color: #262932 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .user-status table td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .user-status table th {
        border-top: none !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #donut-color-chart-morris-daily svg,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #donut-color-chart-morris svg,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #browser-uses-chart svg,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #website-visiter-chart svg {
        opacity: 0.5;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder-header-1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder-2-header {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .show > .nav-link {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .nav-link {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .nav-link.active {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .nav-pills.nav-primary .show > .nav-link {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .nav-pills.nav-primary .nav-link {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .nav-pills.nav-primary .nav-link.active {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .drag-box fieldset {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .help-block {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body #viewhtml .render {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
        border-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder-column .drag-bx {
        border: 1px dotted #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .wizard-4 ul.anchor li a.disabled {
        color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .gallery-with-description h4 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .gallery-with-description a > div {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .gallery-with-description p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-grid-header {
        background-color: #262932;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-header-cell,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-cell,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-header-cell,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-cell {
        background: #262932;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-header-cell input,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-cell input,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-header-cell input,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-cell input {
        background-color: #1d1e26;
        border-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-header-cell input:focus,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-cell input:focus,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-header-cell input:focus,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-cell input:focus {
        outline: none;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row select,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row select {
        background-color: #1d1e26;
        border-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-row > .jsgrid-cell {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-alt-row > .jsgrid-cell {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-cell,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-grid-body {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-selected-row > .jsgrid-cell {
        background-color: #1c212b;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid .jsgrid-pager [class*="jsgrid-pager"] {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid .jsgrid-pager .jsgrid-pager-page a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-pager-current-page {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 700;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .sub-title {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon li a:hover {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon .separator {
        border-bottom: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according li .text-muted {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon.default-according.style-1 li button:hover {
        color: var(--theme-deafult) !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .nav-list .nav-list-disc li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .nav-list .nav-list-disc li:hover {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .nav-list .nav-list-disc li:hover a {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div {
        background-color: #1d1e26;
        -webkit-box-shadow: 0 0 14px 0 #1d1e26;
        box-shadow: 0 0 14px 0 #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li p {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg path,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg line,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg polyline,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg polygon,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg rect {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg path,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg line,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg polyline,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg polygon,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg rect {
        color: var(--theme-deafult) !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .onhover-dropdown:hover .onhover-show-div:before {
        border-bottom: 7px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card .btn-link {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card .card-body {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card .card-body {
        border: 1px solid #374558;
        border-top: none;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .border {
        border: 1px solid #374558 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-date {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details .blog-social li,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main .blog-social li {
        color: #98a6ad;
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details .blog-social li:first-child,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main .blog-social li:first-child {
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details .blog-social li:last-child,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main .blog-social li:last-child {
        border-right: none;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details p,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details .single-blog-content-top,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main .single-blog-content-top {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details .single-blog-content-top p,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main .single-blog-content-top p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box .media h6 span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box .media img {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box .comment-social li {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box .comment-social li:first-child {
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box hr {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .table-hover > tbody > tr:hover {
        --bs-table-accent-bg: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .table-hover > tbody > tr:hover td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .table-hover > tbody > tr:hover th {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body ul.the-icons li {
        border: 1px dotted #374558;
        color: rgba(255, 255, 255, 0.6);
        display: inline-block;
        padding: 10px;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body ul.the-icons li:hover {
        background: #000;
        -webkit-box-shadow: 0 0 3px #262932;
        box-shadow: 0 0 3px #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body ul.the-icons li em {
        display: none;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .button-builder-wrap .box {
        background-color: #262932;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .button-builder-wrap .button-preview h2 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .button-builder-wrap pre.well {
        background-color: #262932 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .crm-activity > li + li {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .crm-activity > li h6 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .crm-activity ul.dates li {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body #donut-color-chart-morris svg text,
    body.dark-only .page-wrapper .page-body-wrapper .page-body #donut-color-chart-morris-daily svg text {
        fill: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .card-profile img {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .profile-details h6 {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .card-social li a {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .card-footer > div h6 {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .card-footer > div + div {
        border-left: 1px solid #323846;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-control {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .checkbox label::before,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .radio label::before {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-basic .dropdown .dropdown-content {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-basic .dropdown .dropdown-content a {
        color: rgba(255, 255, 255, 0.6);
        background-color: rgba(0, 0, 0, 0);
        border-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-basic .dropdown .dropdown-content a:hover {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-basic .dropdown .dropdown-content .dropdown-header {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-basic .dropdown .dropdown-content .dropdown-divider {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropup-basic .dropup .dropup-content {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropup-basic .dropup .dropup-content a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropup-basic .dropup .dropup-content a:hover,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropup-basic .dropup .dropup-content a.active {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropzone.dz-clickable .dz-message h6 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropzone.dz-clickable .dz-message span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropzone .dz-preview {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropzone .dz-preview .dz-details {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .browser-widget .media-body {
        -webkit-column-rule: 1px solid #374558;
        -moz-column-rule: 1px solid #374558;
        column-rule: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .browser-widget .media-body h4 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .browser-widget .media-body h4 span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .browser-widget .media-body span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-app-sidebar .media img {
        border: 2px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-app-sidebar .main-menu > li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-app-sidebar .main-menu > li a:hover {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-app-sidebar ul li a > .title {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-right-aside .email-body .row .col-xl-4 {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-right-aside .email-body .inbox .media.active {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .media-body h6 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .media-body h6 small {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .media-body h6 small span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .media-body p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-top {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-content .email-top .user-emailid:after {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-menu {
        background-color: #1d1e26;
        border: 1px solid #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-menu a {
        color: rgba(255, 255, 255, 0.6);
        background: rgba(0, 0, 0, 0);
        border-top-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-menu a:hover {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .icon-lists div i {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .icon-lists div:hover {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins .media .media-body > span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins .media .details {
        border-left: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins:before,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins:after {
        background-color: #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .redial-social-widget i {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .social-widget-card h5,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .social-widget-card h4 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .social-widget-card span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .b-b-light {
        border-bottom: 1px solid #374558 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .b-r-dark {
        border-right: 1px solid #fff !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial i {
        color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial h5 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .grid-showcase span {
        background-color: #262932;
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .grid-align .row {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-html-output {
        background-color: #1d1e26;
        color: #98a6ad;
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .btn-grid {
        background-color: #262932;
        color: #98a6ad !important;
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-mainControls .ge-addRowGroup .ge-row-icon .column {
        border-left: 3px solid #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .btn-code,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .btn-screen {
        color: #98a6ad;
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-canvas.ge-editing .row {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-canvas.ge-editing .column {
        border: 1px solid #1d1e26;
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-canvas.ge-editing .ge-tools-drawer .ge-details input {
        background-color: #1d1e26;
        border-color: #1d1e26;
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-canvas.ge-editing .ge-tools-drawer .ge-details .btn-group a {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-content-type-ckeditor {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .search-page .info-block {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card-absolute .bg-primary h5,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card-absolute .bg-secondary h5 {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .timeline-small .media .timeline-round.timeline-line-1:after,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .timeline-small .media .timeline-round.small-line:after,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .timeline-small .media .timeline-round.medium-line:after {
        background-color: #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .footer {
        border-top: 1px solid #1d1e26;
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .footer p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .custom-select,
    body.dark-only .page-wrapper .page-body-wrapper .custom-file-label {
        background: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .footer-fix {
        background-color: #262932;
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .note textarea {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .dt-button-info {
        background-color: #262932;
        border: 1px solid #98a6ad;
    }
    body.dark-only .page-wrapper .dt-button-info h2 {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .chat-box .about .name {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .chat-box .chat-menu {
        border-left: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-menu .nav-tabs {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-menu .nav-tabs .nav-item .nav-link.active {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .image .icon-wrapper {
        background-color: #262932;
        -webkit-box-shadow: 1px 1px 3px 1px #1d1e26;
        box-shadow: 1px 1px 3px 1px #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .image .avatar img {
        border: 5px solid #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .social-media a {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .follow span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .follow .follow-num {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .chat-box .status {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .chat-box .status p {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-header {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-header img {
        -webkit-box-shadow: 1px 1px 4px 1px #1d1e26;
        box-shadow: 1px 1px 4px 1px #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-msg-box .my-message {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-msg-box .message {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-msg-box .other-message {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-message {
        background-color: #262932;
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-message .input-group .form-control {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .chat-box .chat-history .call-icons ul li {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-history .call-icons ul li a {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper pre {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .scorlled {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .input-group-air {
        -webkit-box-shadow: 0 3px 20px 0 #1d1e26;
        box-shadow: 0 3px 20px 0 #1d1e26;
    }
    body.dark-only .page-wrapper .input-group-text {
        background-color: #262932;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .input-group-text i {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .input-group-solid .input-group-text,
    body.dark-only .page-wrapper .input-group-solid .form-control {
        background: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .semilight-bg-color,
    body.dark-only .page-wrapper .header-bg-color {
        background-color: #fff;
    }
    body.dark-only .page-wrapper .list-group-item {
        background-color: #262932;
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .list-group-item + .list-group-item {
        border-top-width: 0;
    }
    body.dark-only .page-wrapper .list-group-item.active {
        background-color: var(--theme-deafult) !important;
        border-color: var(--theme-deafult);
        color: #fff;
    }
    body.dark-only .page-wrapper .list-group-item:hover,
    body.dark-only .page-wrapper .list-group-item:focus {
        z-index: 1;
        text-decoration: none;
    }
    body.dark-only .page-wrapper .list-group-item-action:hover:not(.active),
    body.dark-only .page-wrapper .list-group-item-action:focus {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .list-group-flush .list-group-item {
        border-left: 0;
        border-right: 0;
        border-radius: 0;
    }
    body.dark-only .page-wrapper .list-group-item-primary {
        color: #004085;
        background-color: #b8daff;
    }
    body.dark-only .page-wrapper .list-group-item-secondary {
        color: #383d41;
        background-color: #d6d8db;
    }
    body.dark-only .page-wrapper .list-group-item-success {
        color: #155724;
        background-color: #c3e6cb;
    }
    body.dark-only .page-wrapper .list-group-item-danger {
        color: #721c24;
        background-color: #f5c6cb;
    }
    body.dark-only .page-wrapper .list-group-item-warning {
        color: #856404;
        background-color: #ffeeba;
    }
    body.dark-only .page-wrapper .list-group-item-info {
        color: #0c5460;
        background-color: #bee5eb;
    }
    body.dark-only .page-wrapper .list-group-item-light {
        color: #818182;
        background-color: #fdfdfe;
    }
    body.dark-only .page-wrapper .list-group-item-dark {
        color: #1b1e21;
        background-color: #c6c8ca;
    }
    body.dark-only .page-wrapper .auth-bg {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
    }
    body.dark-only .page-wrapper .auth-bg .authentication-box .card {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .form-group input {
        color: rgba(255, 255, 255, 0.6);
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .form-group label {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .checkbox label {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .checkbox label::before {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .auth-bg .authentication-box h3,
    body.dark-only .page-wrapper .auth-bg .authentication-box h4,
    body.dark-only .page-wrapper .auth-bg .authentication-box h6 {
        color: #fff;
    }
    body.dark-only .page-wrapper .auth-bg-video {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.7);
    }
    body.dark-only .page-wrapper .auth-bg-video .authentication-box h4,
    body.dark-only .page-wrapper .auth-bg-video .authentication-box h3 {
        color: #fff;
    }
    body.dark-only .page-wrapper .auth-bg-video .authentication-box h6 {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .auth-bg-video .authentication-box .card {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .auth-bg-video .authentication-box .card .theme-form .form-group input[type="text"],
    body.dark-only .page-wrapper .auth-bg-video .authentication-box .card .theme-form .form-group input[type="password"] {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .auth-bg-video .authentication-box .card .theme-form .checkbox label::before {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .error-wrapper .maintenance-icons li i {
        color: #1d1e26;
    }
    body.dark-only .page-wrapper .error-wrapper .maintenance-icons li:nth-child(3) i {
        color: #1d1e26;
    }
    body.dark-only .page-wrapper .modal-content {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .modal-content .modal-header {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .modal-content .modal-header .close {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .modal-content .modal-footer {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .comingsoon {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
    }
    body.dark-only .page-wrapper .comingsoon .comingsoon-inner h5 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .comingsoon .comingsoon-inner .countdown {
        border-top: 1px solid #1d1e26;
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .comingsoon .comingsoon-inner .countdown .title {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .comingsoon .comingsoon-inner .coming-soon-bottom-link a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .theme-form .login-divider {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .theme-form .login-divider:before {
        background: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .authentication-main {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .card {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .reset-password-link {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .theme-form .form-group label {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .theme-form .form-group .form-control {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border-color: #1d1e26;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .theme-form .opt-box {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box h4 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box h6 {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box h3 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card .theme-form .form-group input[type="text"],
    body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card .theme-form .form-group input[type="password"] {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card .theme-form .checkbox label::before {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .vertical-menu-main {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .mega-menu .title {
        color: rgba(255, 255, 255, 0.6);
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .mega-menu .list-unstyled div a:hover {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .default-according .card {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .default-according .card .btn-link {
        background-color: #262932;
        border: 1px solid #374558;
        border-bottom: none;
        color: #fff;
    }
    body.dark-only .page-wrapper .default-according .card .text-muted {
        color: #98a6ad !important;
    }
    body.dark-only .page-wrapper .default-according .bg-primary .btn-link {
        background-color: var(--theme-deafult);
        border: 1px solid var(--theme-deafult);
    }
    body.dark-only .page-wrapper .default-according .bg-secondary .btn-link {
        background-color: var(--theme-secondary);
        border: 1px solid var(--theme-secondary);
    }
    body.dark-only .page-wrapper .collapse .card-body {
        background-color: #262932;
    }
    @media screen and (max-width: 1660px) {
        body.dark-only .page-wrapper .chat-box .chat-history .call-icons ul li {
            border: 1px solid #1d1e26;
        }
        body.dark-only .page-wrapper .chat-box .chat-history .total-time h2 {
            color: rgba(255, 255, 255, 0.6);
        }
        body.dark-only .page-wrapper .caller-img img {
            opacity: 0.7;
        }
        body.dark-only .page-wrapper .chat-box .chat-history .call-content > div {
            background-color: rgba(0, 0, 0, 0.75);
            background-blend-mode: overlay;
        }
    }
    @media only screen and (max-width: 1199.98px) {
        body.dark-only .page-wrapper .left-header .mega-menu-container {
            border-left: 1px solid #374558;
        }
        body.dark-only .page-wrapper .left-header .mega-menu-container .mega-box + .mega-box {
            border-left: none;
        }
    }
    @media only screen and (max-width: 1199px) {
        body.dark-only .page-wrapper .mobile-title.d-none {
            border-bottom: 1px solid #374558;
        }
        body.dark-only .page-wrapper .chat-menu {
            border-top: 1px solid #1d1e26;
            background-color: #262932;
        }
        body.dark-only .page-wrapper .error-wrapper .maintenance-icons li i {
            color: #2c323f;
        }
    }
    @media only screen and (max-width: 575.98px) {
        body.dark-only .page-wrapper .user-profile .hovercard .info .user-designation {
            border-top: 1px solid #1d1e26;
            border-bottom: 1px solid #1d1e26;
        }
        body.dark-only .page-wrapper .widget-joins .border-after-xs:after {
            background-color: #1d1e26;
        }
        body.dark-only .page-wrapper .page-header .header-wrapper .left-header ul li .search-form .form-control-plaintext {
            background-color: #1d1e26;
        }
        body.dark-only .page-wrapper .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar,
        body.dark-only .page-wrapper .page-wrapper.compact-sidebar .page-header .header-wrapper .toggle-sidebar {
            border-right: 1px solid #374558;
        }
    }
    body.dark-only .lg-backdrop {
        background-color: #1d1e26;
    }
    body.dark-only .lg-outer .lg-thumb-outer {
        background-color: #262932;
    }
    body.dark-only .drag {
        background-color: #262932;
    }
    body.dark-only .drag form {
        background: #262932;
        -webkit-box-shadow: 0 0 30px #1d1e26;
        box-shadow: 0 0 30px #1d1e26;
        border: 1px dotted #1d1e26;
    }
    body.dark-only .drag form input {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .drag form .help-block {
        text-transform: capitalize;
        color: #98a6ad;
    }
    body.dark-only .draggable input {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid #1d1e26;
    }
    body.dark-only .draggable p {
        color: #98a6ad;
    }
    body.dark-only .draggable select {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid #1d1e26;
    }
    body.dark-only .draggable .radio label::before,
    body.dark-only .draggable .checkbox label::before {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .select2-container--default .select2-selection--multiple,
    body.dark-only .select2-container--default .select2-selection--single {
        background-color: #1d1e26;
        border: 1px solid #1d1e26 !important;
    }
    body.dark-only .select2-container--default .select2-search--inline .select2-search__field {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .select2-container--default .select2-search--dropdown .select2-search__field {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .select2-container--default .select2-results__option--highlighted[aria-selected] {
        background-color: #262932 !important;
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .select2-dropdown {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .select2-drpdwn .form-control-primary {
        border-color: var(--theme-deafult) !important;
        color: var(--theme-deafult) !important;
    }
    body.dark-only .select2-drpdwn .form-control-secondary {
        border-color: var(--theme-secondary) !important;
        color: var(--theme-secondary) !important;
    }
    body.dark-only .select2-drpdwn .form-control-success {
        border-color: #51bb25 !important;
        color: #51bb25 !important;
    }
    body.dark-only .select2-drpdwn .form-control-info {
        border-color: #a927f9 !important;
        color: #a927f9 !important;
    }
    body.dark-only .select2-drpdwn .form-control-warning {
        border-color: #f8d62b !important;
        color: #f8d62b !important;
    }
    body.dark-only .select2-drpdwn .form-control-danger {
        border-color: #dc3545 !important;
        color: #dc3545 !important;
    }
    body.dark-only .select2-drpdwn .form-control-inverse {
        border-color: #fff !important;
        color: #fff !important;
    }
    body.dark-only .select2-drpdwn .form-control-primary-fill {
        background-color: var(--theme-deafult) !important;
        color: #fff !important;
    }
    body.dark-only .select2-drpdwn .form-control-secondary-fill {
        background-color: var(--theme-secondary) !important;
        color: #fff !important;
    }
    body.dark-only .select2-drpdwn .form-control-success-fill {
        background-color: #51bb25 !important;
        color: #fff !important;
    }
    body.dark-only .select2-drpdwn .form-control-info-fill {
        background-color: #a927f9 !important;
        color: #fff !important;
    }
    body.dark-only .select2-drpdwn .form-control-warning-fill {
        background-color: #f8d62b !important;
        color: #fff !important;
    }
    body.dark-only .select2-drpdwn .form-control-danger-fill {
        background-color: #dc3545 !important;
        color: #fff !important;
    }
    body.dark-only .select2-drpdwn .form-control-inverse-fill {
        background-color: #fff !important;
        color: #000 !important;
    }
    body.dark-only .select2-container--default.select2-container--disabled .select2-selection--single {
        background-color: #1d1e26;
    }
    body.dark-only .theme-form .form-group select.form-control:not([size]):not([multiple]) {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border-color: #1d1e26;
    }
    body.dark-only .theme-form .form-group input[type="text"],
    body.dark-only .theme-form .form-group input[type="email"],
    body.dark-only .theme-form .form-group input[type="search"],
    body.dark-only .theme-form .form-group input[type="password"],
    body.dark-only .theme-form .form-group input[type="number"],
    body.dark-only .theme-form .form-group input[type="tel"],
    body.dark-only .theme-form .form-group input[type="date"],
    body.dark-only .theme-form .form-group input[type="datetime-local"],
    body.dark-only .theme-form .form-group input[type="time"],
    body.dark-only .theme-form .form-group input[type="datetime-local"],
    body.dark-only .theme-form .form-group input[type="month"],
    body.dark-only .theme-form .form-group input[type="week"],
    body.dark-only .theme-form .form-group input[type="url"],
    body.dark-only .theme-form .form-group input[type="file"],
    body.dark-only .theme-form .form-group select {
        border-color: #1d1e26;
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .theme-form .form-group input[type="text"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="email"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="search"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="password"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="number"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="tel"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="date"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="datetime-local"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="time"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="datetime-local"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="month"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="week"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="url"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="file"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group select::-webkit-input-placeholder {
        color: #98a6ad;
    }
    body.dark-only .theme-form .form-group .form-control {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .theme-form .form-group textarea {
        border-color: #1d1e26;
    }
    body.dark-only .theme-form .form-divider {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .theme-form .form-divider::before {
        background: #262932;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .CodeMirror {
        background: #262932;
        border: 1px solid #98a6ad;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .CodeMirror .CodeMirror-code pre {
        background: #262932;
    }
    body.dark-only .editor-toolbar {
        border-top: 1px solid #98a6ad;
        border-left: 1px solid #98a6ad;
        border-right: 1px solid #98a6ad;
    }
    body.dark-only .editor-toolbar a {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .editor-toolbar a:hover,
    body.dark-only .editor-toolbar a.active {
        background: #1d1e26;
    }
    body.dark-only .editor-toolbar i.separator {
        border-left: 1px solid #98a6ad;
        border-right: 1px solid #98a6ad;
    }
    body.dark-only .editor-toolbar.fullscreen::before {
        background: -webkit-gradient(linear, left top, right top, color-stop(0, #1d1e26), to(rgba(0, 0, 0, 0)));
        background: linear-gradient(to right, #1d1e26 0, rgba(0, 0, 0, 0) 100%);
    }
    body.dark-only .editor-toolbar.fullscreen::after {
        background: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(0, 0, 0, 0)), to(#1d1e26));
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, #1d1e26 100%);
    }
    body.dark-only .editor-toolbar.disabled-for-preview a:not(.no-disable) {
        background: #1d1e26;
    }
    body.dark-only .editor-preview {
        background-color: #262932;
    }
    body.dark-only .editor-toolbar.fullscreen,
    body.dark-only .editor-preview-side {
        background: #262932;
        border: 1px solid #98a6ad;
    }
    body.dark-only .u-step {
        background: #1d1e26;
    }
    body.dark-only .u-step.active,
    body.dark-only .u-step.current {
        background: var(--theme-deafult);
        color: #fff;
    }
    body.dark-only .u-step-title,
    body.dark-only .u-pearl-title {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .u-step-number {
        background-color: #262932;
    }
    body.dark-only .u-pearl:before {
        background-color: #1d1e26;
    }
    body.dark-only .u-pearl-number,
    body.dark-only .u-pearl-icon {
        background: #1d1e26;
        border: 2px solid #1d1e26;
    }
    body.dark-only .u-pearl.disabled .u-pearl-icon,
    body.dark-only .u-pearl.disabled .u-pearl-number {
        background: #1d1e26;
        border: 2px solid #1d1e26;
    }
    body.dark-only .u-pearl.disabled:after {
        background-color: #334053;
    }
    body.dark-only .u-pearl.error:after {
        background-color: #1d1e26;
    }
    body.dark-only .note-editor.note-frame {
        border-color: #1d1e26;
    }
    body.dark-only .note-editor.note-frame .note-editing-area .note-editable {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .swal-modal {
        background-color: #262932;
    }
    body.dark-only .swal-modal .swal-title {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .swal-modal .swal-text {
        color: #98a6ad;
    }
    body.dark-only .swal-modal .swal-content__input {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .nav-tabs {
        border-bottom: 1px solid #374558;
    }
    body.dark-only .nav-tabs .nav-link {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .nav-tabs .nav-link.active {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
        border-color: #374558 #374558 #262932;
    }
    body.dark-only .nav-tabs .nav-link:hover,
    body.dark-only .nav-tabs .nav-link:focus {
        border-color: #374558 #374558 #323846;
    }
    body.dark-only .nav-tabs .nav-item.show .nav-link {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
        border-color: #1d1e26 #1d1e26 #262932;
    }
    body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.active,
    body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.focus,
    body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.hover {
        border-color: #262932 #1d1e26 #1d1e26;
    }
    body.dark-only .border-tab.nav-left .nav-link,
    body.dark-only .border-tab.nav-right .nav-link {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .border-tab.nav-left .nav-link.active,
    body.dark-only .border-tab.nav-right .nav-link.active {
        color: var(--theme-deafult);
    }
    body.dark-only .border-tab.nav-left .show > .nav-link,
    body.dark-only .border-tab.nav-right .show > .nav-link {
        color: var(--theme-deafult);
    }
    body.dark-only .border-tab.nav-left.nav-secondary .nav-link.active,
    body.dark-only .border-tab.nav-left.nav-secondary .nav-link:focus,
    body.dark-only .border-tab.nav-left.nav-secondary .nav-link:hover {
        border-left-color: var(--theme-secondary);
        color: var(--theme-secondary) !important;
    }
    body.dark-only .border-tab.nav-left.nav-secondary .show > .nav-link {
        border-left-color: var(--theme-secondary);
        color: var(--theme-secondary) !important;
    }
    body.dark-only .border-tab.nav-left.nav-secondary .nav-item.show {
        color: var(--theme-secondary) !important;
        border-left-color: var(--theme-secondary);
    }
    body.dark-only .border-tab.nav-left.nav-success .nav-link.active,
    body.dark-only .border-tab.nav-left.nav-success .nav-link:focus,
    body.dark-only .border-tab.nav-left.nav-success .nav-link:hover {
        color: #51bb25 !important;
    }
    body.dark-only .border-tab.nav-left.nav-success .show > .nav-link {
        border-left-color: #51bb25;
        color: #51bb25 !important;
    }
    body.dark-only .border-tab.nav-left.nav-success .nav-item.show {
        color: #51bb25 !important;
        border-left-color: #51bb25;
    }
    body.dark-only .border-tab.nav-right.nav-info .nav-link.active,
    body.dark-only .border-tab.nav-right.nav-info .nav-link:focus,
    body.dark-only .border-tab.nav-right.nav-info .nav-link:hover {
        color: #a927f9 !important;
    }
    body.dark-only .border-tab.nav-right.nav-info .show > .nav-link {
        border-left-color: #a927f9;
        color: #a927f9 !important;
    }
    body.dark-only .border-tab.nav-right.nav-info .nav-item.show {
        color: #a927f9 !important;
        border-left-color: #a927f9;
    }
    body.dark-only .border-tab.nav-secondary .nav-link.active,
    body.dark-only .border-tab.nav-secondary .nav-link:focus,
    body.dark-only .border-tab.nav-secondary .nav-link:hover {
        color: var(--theme-secondary) !important;
    }
    body.dark-only .border-tab.nav-secondary .nav-item.show {
        color: var(--theme-secondary) !important;
    }
    body.dark-only .border-tab.nav-success .nav-link.active,
    body.dark-only .border-tab.nav-success .nav-link:focus,
    body.dark-only .border-tab.nav-success .nav-link:hover {
        color: #51bb25 !important;
    }
    body.dark-only .border-tab.nav-success .nav-item.show {
        color: #51bb25 !important;
    }
    body.dark-only .dropdown-divider {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .icon-hover-bottom {
        background-color: #262932;
        -webkit-box-shadow: 0px 0px 1px 1px #1d1e26;
        box-shadow: 0px 0px 1px 1px #1d1e26;
    }
    body.dark-only .icon-hover-bottom .icon-title {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .icon-hover-bottom span {
        color: #98a6ad;
    }
    body.dark-only .icon-hover-bottom .form-group input {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid #1d1e26;
    }
    body.dark-only .icon-hover-bottom .icon-first i {
        color: #98a6ad;
    }
    body.dark-only code {
        background-color: #1d1e26;
        border-radius: 2px;
    }
    body.dark-only #cd-timeline::before {
        background: #1d1e26;
    }
    body.dark-only .timeliny {
        border-top: 1px solid #1d1e26;
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .timeliny::before {
        background-color: #fff;
    }
    body.dark-only .timeliny .timeliny-dot {
        background-color: #262932;
        border: 1px solid #fff;
    }
    body.dark-only .timeliny .timeliny-dot::before {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot::before {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot::after {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border: none;
    }
    body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock:not(.inactive):not(.active) .timeliny-dot:hover {
        background-color: #fff;
    }
    body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock:not(.inactive):not(.active) .timeliny-dot:hover::before {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock .timeliny-dot:hover::after {
        background-color: #262932;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .todo .todo-list-wrapper #todo-list li .task-container {
        background: #262932;
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .todo .todo-list-wrapper #todo-list li .task-container .task-label {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .todo .todo-list-wrapper #todo-list li .task-container:hover h4 {
        color: #fff;
    }
    body.dark-only .todo .todo-list-wrapper #todo-list li .task-container .task-action-btn .action-box:hover:not(.active) {
        background: #262932;
        border: 1px solid #1d1e26;
    }
    body.dark-only .todo .todo-list-wrapper #todo-list li.completed .task-container .task-label {
        color: var(--theme-deafult);
    }
    body.dark-only .todo .todo-list-wrapper #todo-list li.completed .task-container .complete-btn:hover {
        border: 1px solid #51bb25 !important;
    }
    body.dark-only .todo .todo-list-wrapper .todo-list-footer .new-task-wrapper textarea {
        background-color: #262932;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .user-profile .ttl-info h6 {
        color: #98a6ad;
    }
    body.dark-only .user-profile .ttl-info span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .user-profile .hovercard .info .title a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .user-profile .hovercard .user-image .avatar img {
        border: 10px solid #262932;
    }
    body.dark-only .user-profile .hovercard .user-image .icon-wrapper {
        background-color: #262932;
    }
    body.dark-only .user-profile .hovercard .tabs-scoial {
        border-bottom: none !important;
    }
    body.dark-only .user-profile .follow .follow-num {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .user-profile .profile-img-style .user-name {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .user-profile .profile-img-style p {
        color: #98a6ad;
    }
    body.dark-only .introjs-helperLayer {
        background-color: rgba(54, 64, 74, 0.9);
        border: rgba(255, 255, 255, 0.5);
    }
    body.dark-only .jstree-default .jstree-node,
    body.dark-only .jstree-default .jstree-icon {
        color: #455869 !important;
    }
    body.dark-only .jstree-default .jstree-anchor {
        color: #98a6ad;
    }
    body.dark-only .jstree-default .jstree-clicked {
        color: #fff;
        background-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .jstree-default .jstree-hovered {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only span.twitter-typeahead .tt-menu {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only span.twitter-typeahead .tt-suggestion {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only span.twitter-typeahead .tt-suggestion:hover,
    body.dark-only span.twitter-typeahead .tt-suggestion:focus {
        background-color: #262932;
    }
    body.dark-only .typography small {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .code-box-copy pre {
        background-color: #1d1e26;
    }
    body.dark-only .code-box-copy pre code {
        background-color: #1d1e26;
    }
    body.dark-only .code-box-copy pre[class*="language-"] {
        border: 1px solid #1d1e26;
    }
    body.dark-only .code-box-copy .code-box-copy__btn {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: #fff;
    }
    body.dark-only .code-box-copy code[class*="language-"],
    body.dark-only .code-box-copy pre[class*="language-"] {
        text-shadow: 0px 1px #000;
    }
    body.dark-only .code-box-copy code[class*="language-"] ::-moz-selection,
    body.dark-only .code-box-copy pre[class*="language-"] ::-moz-selection {
        text-shadow: none;
        background: #262932;
    }
    body.dark-only .code-box-copy code[class*="language-"] ::selection,
    body.dark-only .code-box-copy pre[class*="language-"] ::selection {
        text-shadow: none;
        background: #262932;
    }
    body.dark-only table.fixedHeader-floating {
        background-color: #1d1e26;
    }
    body.dark-only .note textarea {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .dt-button-info {
        background-color: #262932;
        border: 1px solid #1d1e26;
    }
    body.dark-only .dt-button-info h2 {
        background-color: #262932;
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only pre {
        background-color: #1d1e26;
    }
    body.dark-only #keytable_wrapper .dataTables_paginate,
    body.dark-only #column-selector_wrapper .dataTables_paginate,
    body.dark-only #auto-fill_wrapper .dataTables_paginate,
    body.dark-only #custom-button_wrapper .dataTables_paginate,
    body.dark-only #class-button_wrapper .dataTables_paginate,
    body.dark-only #keyboard-btn_wrapper .dataTables_paginate,
    body.dark-only #multilevel-btn_wrapper .dataTables_paginate,
    body.dark-only #pagelength-btn_wrapper .dataTables_paginate,
    body.dark-only #basic-colreorder_wrapper .dataTables_paginate,
    body.dark-only #state-saving_wrapper .dataTables_paginate,
    body.dark-only #real-time_wrapper .dataTables_paginate,
    body.dark-only #basic-fixed-header_wrapper .dataTables_paginate,
    body.dark-only #fixed-header-footer_wrapper .dataTables_paginate,
    body.dark-only #export-button_wrapper .dataTables_paginate,
    body.dark-only #excel-cust-bolder_wrapper .dataTables_paginate,
    body.dark-only #cust-json_wrapper .dataTables_paginate,
    body.dark-only #basic-key-table_wrapper .dataTables_paginate,
    body.dark-only #focus-cell_wrapper .dataTables_paginate,
    body.dark-only #responsive_wrapper .dataTables_paginate,
    body.dark-only #new-cons_wrapper .dataTables_paginate,
    body.dark-only #show-hidden-row_wrapper .dataTables_paginate,
    body.dark-only #basic-row-reorder_wrapper .dataTables_paginate,
    body.dark-only #full-row_wrapper .dataTables_paginate,
    body.dark-only #rest-column_wrapper .dataTables_paginate {
        border: none;
    }
    body.dark-only #example-style-8_wrapper table.dataTable.stripe tbody tr.odd,
    body.dark-only #example-style-8_wrapper table.dataTable.display tbody tr.odd {
        background-color: #1d1e26;
    }
    body.dark-only .error-wrapper {
        background-color: rgba(54, 64, 74, 0.8);
    }
    body.dark-only .error-wrapper .sub-content {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .b-light {
        border: 1px solid #1d1e26 !important;
    }
    body.dark-only .modal-content {
        background-color: #262932;
    }
    body.dark-only .modal-content .modal-header {
        border-bottom: 1px solid #374558;
    }
    body.dark-only .modal-content .modal-header .close {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 400;
    }
    body.dark-only .modal-content .ui-front .form-control {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid #374558;
    }
    body.dark-only .stepwizard .stepwizard-row:before {
        background-color: #374558;
    }
    body.dark-only .modal .theme-close {
        background-color: #262932 !important;
        color: rgba(255, 255, 255, 0.2);
    }
    body.dark-only .token.atrule,
    body.dark-only .token.attr-value,
    body.dark-only .token.keyword {
        color: #a927f9;
    }
    body.dark-only .token.boolean,
    body.dark-only .token.constant,
    body.dark-only .token.deleted,
    body.dark-only .token.number,
    body.dark-only .token.property,
    body.dark-only .token.symbol,
    body.dark-only .token.tag {
        color: #dc3545;
    }
    body.dark-only .loader-box.card-loader {
        background-color: #262932;
    }
    body.dark-only .my-gallery.gallery-with-description img {
        border: 1px solid #1d1e26 !important;
        border-bottom: none !important;
    }
    body.dark-only .alert-primary.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-secondary.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-success.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-danger.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-warning.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-info.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-light.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-dark.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-theme span + span + span {
        border-left: 5px solid var(--theme-deafult);
        background-color: #1d1e26;
        border-radius: 4px;
    }
    body.dark-only .alert-theme i {
        color: #fff;
        padding: 20px;
        margin-right: 20px !important;
    }
    body.dark-only .user-card .user-deatils h6 {
        color: #98a6ad;
    }
    body.dark-only .user-card .card-footer > div:first-child {
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .user-card .card-footer .user-footer h6 {
        color: #98a6ad;
    }
    body.dark-only .user-card .card-footer .user-footer svg path,
    body.dark-only .user-card .card-footer .user-footer svg rect {
        color: #98a6ad;
    }
    body.dark-only .order-history table .qty-box button {
        background-color: #1d1e26 !important;
        border: none !important;
    }
    body.dark-only .order-history table .qty-box .input-group {
        border-color: #374558;
    }
    body.dark-only .order-history table thead tr th {
        background-color: #1d1e26;
    }
    body.dark-only .order-history table tr td,
    body.dark-only .order-history table tr th {
        border-top: none !important;
    }
    body.dark-only .order-history .title-orders {
        background-color: #1d1e26;
    }
    body.dark-only .navigation-option ul li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .navigation-option ul li:hover {
        background-color: #1d1e26;
    }
    body.dark-only .product-box .product-details h6 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .product-box .product-details span {
        color: #98a6ad;
    }
    body.dark-only .product-box .product-img .product-hover ul li {
        background-color: #262932;
    }
    body.dark-only .product-box .product-img .product-hover ul li:hover {
        background-color: #000;
        color: #fff;
    }
    body.dark-only .browse .browse-articles ul li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .browse .browse-articles ul li:hover {
        background-color: #1d1e26;
    }
    body.dark-only .radio-primary input[type="radio"] + label::before {
        border-color: var(--theme-deafult) !important;
    }
    body.dark-only .radio-primary input[type="radio"] + label::after {
        background-color: var(--theme-deafult);
    }
    body.dark-only .radio-primary input[type="radio"]:checked + label::before {
        border-color: var(--theme-deafult) !important;
    }
    body.dark-only .radio-primary input[type="radio"]:checked + label::after {
        background-color: var(--theme-deafult);
    }
    body.dark-only .radio-secondary input[type="radio"] + label::before {
        border-color: #f73164 !important;
    }
    body.dark-only .radio-secondary input[type="radio"] + label::after {
        background-color: #f73164;
    }
    body.dark-only .radio-secondary input[type="radio"]:checked + label::before {
        border-color: #f73164 !important;
    }
    body.dark-only .radio-secondary input[type="radio"]:checked + label::after {
        background-color: #f73164;
    }
    body.dark-only .radio-success input[type="radio"] + label::before {
        border-color: #51bb25 !important;
    }
    body.dark-only .radio-success input[type="radio"] + label::after {
        background-color: #51bb25;
    }
    body.dark-only .radio-success input[type="radio"]:checked + label::before {
        border-color: #51bb25 !important;
    }
    body.dark-only .radio-success input[type="radio"]:checked + label::after {
        background-color: #51bb25;
    }
    body.dark-only .radio-danger input[type="radio"] + label::before {
        border-color: #dc3545 !important;
    }
    body.dark-only .radio-danger input[type="radio"] + label::after {
        background-color: #dc3545;
    }
    body.dark-only .radio-danger input[type="radio"]:checked + label::before {
        border-color: #dc3545 !important;
    }
    body.dark-only .radio-danger input[type="radio"]:checked + label::after {
        background-color: #dc3545;
    }
    body.dark-only .radio-info input[type="radio"] + label::before {
        border-color: #a927f9 !important;
    }
    body.dark-only .radio-info input[type="radio"] + label::after {
        background-color: #a927f9;
    }
    body.dark-only .radio-info input[type="radio"]:checked + label::before {
        border-color: #a927f9 !important;
    }
    body.dark-only .radio-info input[type="radio"]:checked + label::after {
        background-color: #a927f9;
    }
    body.dark-only .radio-light input[type="radio"] + label::before {
        border-color: #f4f4f4 !important;
    }
    body.dark-only .radio-light input[type="radio"] + label::after {
        background-color: #f4f4f4;
    }
    body.dark-only .radio-light input[type="radio"]:checked + label::before {
        border-color: #f4f4f4 !important;
    }
    body.dark-only .radio-light input[type="radio"]:checked + label::after {
        background-color: #f4f4f4;
    }
    body.dark-only .radio-dark input[type="radio"] + label::before {
        border-color: #2c323f !important;
    }
    body.dark-only .radio-dark input[type="radio"] + label::after {
        background-color: #2c323f;
    }
    body.dark-only .radio-dark input[type="radio"]:checked + label::before {
        border-color: #2c323f !important;
    }
    body.dark-only .radio-dark input[type="radio"]:checked + label::after {
        background-color: #2c323f;
    }
    body.dark-only .radio-warning input[type="radio"] + label::before {
        border-color: #f8d62b !important;
    }
    body.dark-only .radio-warning input[type="radio"] + label::after {
        background-color: #f8d62b;
    }
    body.dark-only .radio-warning input[type="radio"]:checked + label::before {
        border-color: #f8d62b !important;
    }
    body.dark-only .radio-warning input[type="radio"]:checked + label::after {
        background-color: #f8d62b;
    }
    body.dark-only .checkbox-primary label::before {
        border-color: var(--theme-deafult) !important;
    }
    body.dark-only .checkbox-secondary label::before {
        border-color: #f73164 !important;
    }
    body.dark-only .checkbox-success label::before {
        border-color: #51bb25 !important;
    }
    body.dark-only .checkbox-danger label::before {
        border-color: #dc3545 !important;
    }
    body.dark-only .checkbox-info label::before {
        border-color: #a927f9 !important;
    }
    body.dark-only .checkbox-light label::before {
        border-color: #f4f4f4 !important;
    }
    body.dark-only .checkbox-dark label::before {
        border-color: #2c323f !important;
    }
    body.dark-only .checkbox-warning label::before {
        border-color: #f8d62b !important;
    }
    body.dark-only .checkbox-solid-primary label:before {
        background-color: var(--theme-deafult) !important;
        border-color: var(--theme-deafult) !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-primary input[type="checkbox"]:checked + label::before {
        background-color: var(--theme-deafult) !important;
        border-color: var(--theme-deafult) !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-primary input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-secondary label:before {
        background-color: #f73164 !important;
        border-color: #f73164 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-secondary input[type="checkbox"]:checked + label::before {
        background-color: #f73164 !important;
        border-color: #f73164 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-secondary input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-success label:before {
        background-color: #51bb25 !important;
        border-color: #51bb25 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-success input[type="checkbox"]:checked + label::before {
        background-color: #51bb25 !important;
        border-color: #51bb25 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-success input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-danger label:before {
        background-color: #dc3545 !important;
        border-color: #dc3545 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-danger input[type="checkbox"]:checked + label::before {
        background-color: #dc3545 !important;
        border-color: #dc3545 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-danger input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-info label:before {
        background-color: #a927f9 !important;
        border-color: #a927f9 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-info input[type="checkbox"]:checked + label::before {
        background-color: #a927f9 !important;
        border-color: #a927f9 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-info input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-light label:before {
        background-color: #f4f4f4 !important;
        border-color: #f4f4f4 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-light input[type="checkbox"]:checked + label::before {
        background-color: #f4f4f4 !important;
        border-color: #f4f4f4 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-light input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-dark label:before {
        background-color: #2c323f !important;
        border-color: #2c323f !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-dark input[type="checkbox"]:checked + label::before {
        background-color: #2c323f !important;
        border-color: #2c323f !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-dark input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-warning label:before {
        background-color: #f8d62b !important;
        border-color: #f8d62b !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-warning input[type="checkbox"]:checked + label::before {
        background-color: #f8d62b !important;
        border-color: #f8d62b !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-warning input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .bootstrap-datetimepicker-widget ul li {
        color: rgba(255, 255, 255, 0.6);
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .bootstrap-datetimepicker-widget tr th:hover,
    body.dark-only .bootstrap-datetimepicker-widget tr td:hover {
        background-color: #262932;
    }
    body.dark-only .bootstrap-datetimepicker-widget tr th span:hover,
    body.dark-only .bootstrap-datetimepicker-widget tr td span:hover {
        background-color: #262932;
    }
    body.dark-only .bootstrap-datetimepicker-widget tr th.prev:hover,
    body.dark-only .bootstrap-datetimepicker-widget tr th.next:hover,
    body.dark-only .bootstrap-datetimepicker-widget tr td.prev:hover,
    body.dark-only .bootstrap-datetimepicker-widget tr td.next:hover {
        background-color: #262932;
    }
    body.dark-only .bootstrap-datetimepicker-widget tr:first-child th:hover {
        background-color: #262932;
    }
    body.dark-only .btn-transparent {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only #cal-basic .fc-toolbar .fc-left .fc-next-button .fc-icon-right-single-arrow:after,
    body.dark-only #cal-basic .fc-toolbar .fc-right .fc-next-button .fc-icon-right-single-arrow:after {
        color: #98a6ad;
    }
    body.dark-only #cal-basic .fc-toolbar .fc-left .fc-prev-button .fc-icon-left-single-arrow:after,
    body.dark-only #cal-basic .fc-toolbar .fc-right .fc-prev-button .fc-icon-left-single-arrow:after {
        color: #98a6ad;
    }
    body.dark-only #cal-basic .fc-toolbar .fc-left .fc-button-group .fc-month-button {
        color: #fff !important;
    }
    body.dark-only .fc-button-group .fc-basicWeek-button,
    body.dark-only .fc-button-group .fc-basicDay-button,
    body.dark-only .fc-button-group .fc-agendaWeek-button,
    body.dark-only .fc-button-group .fc-agendaDay-button {
        color: #000 !important;
    }
    body.dark-only .fc-button-group .fc-month-button {
        color: #fff !important;
    }
    body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .fc-month-button,
    body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .btn-light {
        color: #000 !important;
    }
    body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .fc-agendaWeek-button {
        color: #fff !important;
    }
    body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .fc-agendaWeek-button:hover {
        color: #000 !important;
    }
    body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .fc-basicWeek-button,
    body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .btn-light,
    body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .fc-basicWeek-button,
    body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .btn-light,
    body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .fc-basicWeek-button,
    body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .btn-light {
        color: #000 !important;
    }
    body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .fc-month-button,
    body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .fc-month-button,
    body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .fc-month-button {
        color: #fff !important;
    }
    body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .fc-month-button:hover,
    body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .fc-month-button:hover,
    body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .fc-month-button:hover {
        color: #000 !important;
    }
    body.dark-only .categories ul li a {
        color: #98a6ad;
    }
    body.dark-only .categories ul li:hover {
        background-color: #1d1e26;
    }
    body.dark-only .socialprofile .likes-profile h5 span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .socialprofile span {
        color: #98a6ad;
    }
    body.dark-only .social-status .media .media-body span + span {
        color: #98a6ad;
    }
    body.dark-only .social-status .media .media-body p,
    body.dark-only .social-status .media .media-body .light-span {
        color: #98a6ad;
    }
    body.dark-only .filter-cards-view p,
    body.dark-only .timeline-content p {
        color: #98a6ad;
    }
    body.dark-only .filter-cards-view .comment-number i,
    body.dark-only .timeline-content .comment-number i {
        color: #98a6ad;
    }
    body.dark-only .filter-cards-view .comments-box .input-group .form-control,
    body.dark-only .timeline-content .comments-box .input-group .form-control {
        border: none !important;
    }
    body.dark-only .filter-cards-view .comments-box .input-group .input-group-append,
    body.dark-only .timeline-content .comments-box .input-group .input-group-append {
        background-color: #1d1e26;
    }
    body.dark-only .social-chat .media-body {
        border: 1px solid #1d1e26;
    }
    body.dark-only .social-chat .media-body:after {
        border-right: 7px solid #262932;
    }
    body.dark-only .social-chat span span {
        color: #98a6ad;
    }
    body.dark-only .details-about .your-details p {
        color: #98a6ad;
    }
    body.dark-only .activity-log .my-activity p {
        color: #98a6ad;
    }
    body.dark-only .bookmark ul li a {
        color: rgba(255, 255, 255, 0.6);
    }
    @media screen and (max-width: 1366px) {
        body.dark-only .earning-card.card .card-body .earning-content {
            border-bottom: 1px solid #374558;
        }
    }
    @media only screen and (max-width: 991.98px) {
        body.dark-only .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
            border-right: 1px solid #374558;
        }
        body.dark-only .page-wrapper.compact-wrapper .left-header .mega-menu svg,
        body.dark-only .page-wrapper.compact-sidebar .left-header .mega-menu svg {
            stroke: rgba(255, 255, 255, 0.6);
        }
    }
    @media only screen and (max-width: 767.98px) {
        body.dark-only .page-wrapper.compact-wrapper .left-header .mega-menu svg,
        body.dark-only .page-wrapper.compact-sidebar .left-header .mega-menu svg {
            stroke: #fff;
        }
        body.dark-only .page-wrapper.compact-wrapper .left-header .mega-menu .nav-link,
        body.dark-only .page-wrapper.compact-sidebar .left-header .mega-menu .nav-link {
            border-left: 1px solid #374558;
        }
        body.dark-only .page-wrapper.material-icon .page-header {
            background-color: #262932;
        }
        body.dark-only .chart_data_left .card-body .chart-main > div:first-child {
            border-right: 1px solid #374558;
        }
        body.dark-only .chart_data_left .card-body .chart-main > div:nth-child(3) {
            border-right: 1px solid #374558;
        }
    }
    body.dark-only .apexcharts-tooltip.light {
        border-color: #374558;
        background-color: #1d1e26;
    }
    body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-title {
        border-color: #374558;
    }
    body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-text-label,
    body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-text-value {
        color: #fff;
    }
    body.dark-sidebar .toggle-sidebar svg {
        stroke: var(--theme-deafult);
    }
    body.dark-sidebar .page-wrapper .sidebar-main-title p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
        background-color: #262932;
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
        stroke: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
        background-color: rgba(115, 102, 255, 0.2);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
        color: var(--theme-deafult);
        stroke: var(--theme-deafult);
    }
    body.dark-sidebar
        .page-wrapper.compact-wrapper
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-title
        h5 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar
        .page-wrapper.compact-wrapper
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-content.opensubmegamenu
        li
        a {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 400;
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
        background-color: rgba(255, 255, 255, 0.2);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
        color: var(--theme-deafult);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
        background: #262932;
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main {
        background-color: #262932;
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
        background: #262932;
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active,
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover {
        color: var(--theme-deafult);
    }
    body.dark-sidebar
        .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .sidebar-submenu
        li
        .nav-sub-childmenu {
        background: #262932;
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
        background: #262932;
    }
    body.dark-sidebar
        .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-content
        li
        a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow,
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
        background: #262932;
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-title {
        border-bottom: 1px solid #374558;
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
        stroke: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
        background-color: #262932;
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
        border-bottom: 1px solid #374558;
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
        background-color: rgba(115, 102, 255, 0.2);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active span {
        color: var(--theme-deafult);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
        color: var(--theme-deafult);
        stroke: var(--theme-deafult);
    }
    body.dark-sidebar
        .page-wrapper.compact-sidebar
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-title
        h5 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar
        .page-wrapper.compact-sidebar
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-content.opensubmegamenu
        li
        a {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 400;
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
        background-color: rgba(255, 255, 255, 0.2);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
        color: var(--theme-deafult);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper .according-menu i {
        color: rgba(255, 255, 255, 0.6);
    }
    body .for-dark {
        display: none;
    }
    body[class*="dark-"] .for-dark {
        display: block;
    }
    body[class*="dark-"] .for-light {
        display: none;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="border-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link,
    .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="border-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link {
        border-bottom: 1px solid rgba(115, 102, 255, 0.1);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 1) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 1)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 1):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 1):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 1):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 1):hover
        a:hover {
        background-color: rgba(115, 102, 255, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 2) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 2)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 2):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 2):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 2):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 2):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 3) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 3)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 3):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 3):hover
        a
        svg {
        stroke: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 3):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 3):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 4) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 4)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 4):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 4):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 4):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 4):hover
        a:hover {
        background-color: rgba(115, 102, 255, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 5) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 5)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 5):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 5):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 5):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 5):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 6) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 6)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 6):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 6):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 6):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 6):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 7) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 7)
        svg {
        color: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 7):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 7):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 7):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 7):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 8) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 8)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 8):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 8):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 8):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 8):hover
        a:hover {
        background-color: rgba(248, 214, 43, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 9) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 9)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 9):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 9):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 9):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 9):hover
        a:hover {
        background-color: rgba(115, 102, 255, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 10) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 10)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 10):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 10):hover
        a
        svg {
        stroke: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 10):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 10):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 11) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 11)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 11):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 11):hover
        a
        svg {
        stroke: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 11):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 11):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 12) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 12)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 12):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 12):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 12):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 12):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 13) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 13)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 13):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 13):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 13):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 13):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 14) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 14)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 14):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 14):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 14):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 14):hover
        a:hover {
        background-color: rgba(248, 214, 43, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 15) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 15)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 15):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 15):hover
        a
        svg {
        stroke: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 15):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 15):hover
        a:hover {
        background-color: rgba(247, 49, 100, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 16) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 16)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 16):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 16):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 16):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 16):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 17) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 17)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 17):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 17):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 17):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 17):hover
        a:hover {
        background-color: rgba(220, 53, 69, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 18) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 18)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 18):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 18):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 18):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 18):hover
        a:hover {
        background-color: rgba(220, 53, 69, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 19) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 19)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 19):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 19):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 19):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 19):hover
        a:hover {
        background-color: rgba(115, 102, 255, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 20) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 20)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 20):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 20):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 20):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 20):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 21) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 21)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 21):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 21):hover
        a
        svg {
        stroke: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 21):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 21):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 22) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 22)
        svg {
        color: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 22):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 22):hover
        a
        svg {
        stroke: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 22):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 22):hover
        a:hover {
        background-color: rgba(115, 102, 255, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 23) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 23)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 23):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 23):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 23):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 23):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 24) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 24)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 24):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 24):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 24):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 24):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 25) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 25)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 25):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 25):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 25):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 25):hover
        a:hover {
        background-color: rgba(115, 102, 255, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 26) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 26)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 26):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 26):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 26):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 26):hover
        a:hover {
        background-color: rgba(220, 53, 69, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 27) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 27)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 27):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 27):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 27):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 27):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 28) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 28)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 28):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 28):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 28):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 28):hover
        a:hover {
        background-color: rgba(248, 214, 43, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 29) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 29)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 29):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 29):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 29):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 29):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 30) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 30)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 30):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 30):hover
        a
        svg {
        stroke: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 30):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 30):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 31) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 31)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 31):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 31):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 31):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 31):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 32) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 32)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 32):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 32):hover
        a
        svg {
        stroke: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 32):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 32):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 33) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 33)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 33):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 33):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 33):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 33):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 34) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 34)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 34):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 34):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 34):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 34):hover
        a:hover {
        background-color: rgba(247, 49, 100, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 35) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 35)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 35):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 35):hover
        a
        svg {
        stroke: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 35):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 35):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 36) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 36)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 36):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 36):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 36):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 36):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 37) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 37)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 37):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 37):hover
        a
        svg {
        stroke: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 37):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 37):hover
        a:hover {
        background-color: rgba(220, 53, 69, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 38) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 38)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 38):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 38):hover
        a
        svg {
        stroke: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 38):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 38):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 39) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 39)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 39):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 39):hover
        a
        svg {
        stroke: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 39):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 39):hover
        a:hover {
        background-color: rgba(247, 49, 100, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 40) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 40)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 40):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 40):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 40):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 40):hover
        a:hover {
        background-color: rgba(248, 214, 43, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 41) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 41)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 41):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 41):hover
        a
        svg {
        stroke: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 41):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 41):hover
        a:hover {
        background-color: rgba(247, 49, 100, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 42) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 42)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 42):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 42):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 42):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 42):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 43) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 43)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 43):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 43):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 43):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 43):hover
        a:hover {
        background-color: rgba(248, 214, 43, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 44) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 44)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 44):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 44):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 44):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 44):hover
        a:hover {
        background-color: rgba(247, 49, 100, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 45) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 45)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 45):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 45):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 45):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 45):hover
        a:hover {
        background-color: rgba(115, 102, 255, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 46) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 46)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 46):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 46):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 46):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 46):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 47) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 47)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 47):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 47):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 47):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 47):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 48) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 48)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 48):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 48):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 48):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 48):hover
        a:hover {
        background-color: rgba(248, 214, 43, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 49) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 49)
        svg {
        color: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 49):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 49):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 49):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 49):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 50) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 50)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 50):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 50):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 50):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 50):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 51) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 51)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 51):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 51):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 51):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 51):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 52) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 52)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 52):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 52):hover
        a
        svg {
        stroke: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 52):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 52):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 53) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 53)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 53):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 53):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 53):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 53):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 54) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 54)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 54):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 54):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 54):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 54):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 55) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 55)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 55):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 55):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 55):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 55):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 56) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 56)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 56):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 56):hover
        a
        svg {
        stroke: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 56):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 56):hover
        a:hover {
        background-color: rgba(247, 49, 100, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 57) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 57)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 57):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 57):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 57):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 57):hover
        a:hover {
        background-color: rgba(248, 214, 43, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 58) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 58)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 58):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 58):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 58):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 58):hover
        a:hover {
        background-color: rgba(247, 49, 100, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 59) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 59)
        svg {
        color: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 59):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 59):hover
        a
        svg {
        stroke: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 59):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 59):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 60) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 60)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 60):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 60):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 60):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 60):hover
        a:hover {
        background-color: rgba(220, 53, 69, 0.15) !important;
    }
    .customizer-links {
        position: fixed;
        right: 00px;
        top: 50%;
        z-index: 3;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
        box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
    }
    .customizer-links > .nav {
        border-radius: 8px;
        padding: 10px;
        background-color: #fff;
    }
    .customizer-links.open {
        right: 330px;
        border-radius: 8px 0 0 8px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .customizer-links .nav-link {
        padding: 0;
        position: relative;
    }
    .customizer-links .nav-link:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(115, 102, 255, 0.1);
        border-radius: 5px;
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .customizer-links .nav-link:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        top: 12px;
        border-width: 5px 0 5px 5px;
        border-left-color: #e8e6ff;
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
        color: #fff;
        left: -10px;
        z-index: 2;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .customizer-links .nav-link:hover i {
        color: var(--theme-deafult);
    }
    .customizer-links .nav-link:hover:before,
    .customizer-links .nav-link:hover:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .customizer-links .nav-link:hover span {
        opacity: 1;
        right: 48px;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .customizer-links .nav-link + .nav-link {
        margin-bottom: 5px;
    }
    .customizer-links .nav-link span {
        position: absolute;
        right: -60px;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        text-align: right;
        padding: 7px 10px;
        display: block;
        top: 0;
        border-radius: 5px;
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        opacity: 0;
        color: var(--theme-deafult);
        background-color: #e8e6ff;
        -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
        box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
    }
    .customizer-links .nav-link i {
        font-size: 17px;
        padding: 8px 10px;
        display: inline-block;
        cursor: pointer;
        margin: 0;
        color: gray;
        border-radius: 5px;
    }
    .customizer-contain {
        direction: ltr;
        position: fixed;
        height: calc(100vh);
        top: 82px;
        width: 333px;
        right: -335px;
        background-color: #fff;
        z-index: 8;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .customizer-contain.open {
        right: 0px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -webkit-box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
        box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
    }
    .customizer-contain li {
        position: relative;
    }
    .customizer-contain li.active {
        -webkit-box-shadow: 0 0 11px 5px rgba(226, 226, 226, 0.5);
        box-shadow: 0 0 11px 5px rgba(226, 226, 226, 0.5);
    }
    .customizer-contain h6 {
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.5px;
        margin-top: 15px;
        padding-top: 15px;
        margin-bottom: 10px;
    }
    .customizer-contain .customizer-header {
        padding: 15px 25px;
        border-bottom: 1px solid #f4f4f4;
    }
    .customizer-contain .customizer-header .icon-close {
        position: absolute;
        font-size: 18px;
        cursor: pointer;
        font-weight: 900;
        right: 24px;
        color: var(--theme-deafult);
    }
    .customizer-contain .customizer-header h5 {
        font-weight: 700;
        text-transform: uppercase;
    }
    .customizer-contain .customizer-body {
        padding: 15px 25px;
        max-height: calc(100vh - 200px);
        overflow-y: scroll;
    }
    .customizer-contain .customizer-body .fade:not(.show) {
        display: none;
    }
    .customizer-contain .customizer-body .unlimited-color-layout {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .customizer-contain .customizer-body .unlimited-color-layout .btn {
        font-size: 15px;
        padding: 4px 20px;
    }
    .customizer-contain .customizer-body .unlimited-color-layout input {
        border: 1px solid #ccc;
        height: 35px;
        width: 35px;
        border-radius: 5px;
        background-color: #fff;
        margin-right: 5px;
    }
    .customizer-contain .customizer-body .layout-grid.customizer-mix .color-layout {
        height: 70px;
        width: 80px;
        border: 1px solid #f6f7fb;
        border-radius: 5px;
    }
    .customizer-contain .customizer-body .tab-pane > h6:first-child {
        padding-top: 0;
        margin-top: 0;
    }
    .customizer-contain .customizer-body li {
        cursor: pointer;
        opacity: 0.9;
    }
    .customizer-contain .customizer-body li.active {
        opacity: 1;
    }
    .customizer-contain .customizer-body .color-layout {
        height: 35px;
        width: 35px;
        border-radius: 6px;
        display: inline-block;
        margin-right: 3px;
        border: 1px solid #b8b8b8;
        padding: 3px;
    }
    .customizer-contain .customizer-body .color-layout > div {
        background-color: #03565a;
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }
    .customizer-contain .customizer-body .color-layout[data-attr="color-2"] div {
        background-color: #4831d4;
    }
    .customizer-contain .customizer-body .color-layout[data-attr="color-3"] div {
        background-color: #d64dcf;
    }
    .customizer-contain .customizer-body .color-layout[data-attr="color-4"] div {
        background-color: #4c2fbf;
    }
    .customizer-contain .customizer-body .color-layout[data-attr="color-5"] div {
        background-color: #7c4dff;
    }
    .customizer-contain .customizer-body .color-layout[data-attr="color-6"] div {
        background-color: #3949ab;
    }
    .customizer-contain .customizer-body .dark > li {
        background-color: #2f3c4e;
    }
    .customizer-contain .customizer-body .main-layout > li {
        height: 65px;
        width: 85px;
        border: 1px solid #f4f4f4;
        border-radius: 10px;
        display: inline-block;
    }
    .customizer-contain .customizer-body .main-layout > li + li {
        margin-left: 7px;
    }
    .customizer-contain .customizer-body .main-layout .box-layout .body .badge {
        left: -14px;
    }
    .customizer-contain .customizer-body .sidebar-type > li,
    .customizer-contain .customizer-body .sidebar-setting > li {
        height: 70px;
        width: 85px;
        border: 1px solid #f4f4f4;
        border-radius: 5px;
        display: inline-block;
    }
    .customizer-contain .customizer-body .sidebar-type > li + li,
    .customizer-contain .customizer-body .sidebar-setting > li + li {
        margin-left: 7px;
    }
    .customizer-contain .customizer-body .sidebar-type li[data-attr="normal-sidebar"] .body ul li.bg-dark,
    .customizer-contain .customizer-body .sidebar-type li[data-attr="normal-sidebar"] .body ul li.sidebar {
        width: 100%;
        height: 10px;
        display: block;
    }
    .customizer-contain .customizer-body .sidebar-type li[data-attr="normal-sidebar"] .body ul li.bg-light,
    .customizer-contain .customizer-body .sidebar-type li[data-attr="normal-sidebar"] .body ul li.body {
        height: calc(100% - 10px);
        width: 100%;
    }
    .customizer-contain .customizer-body .sidebar-type li[data-attr="normal-sidebar"].body-compact .body ul li.body {
        width: calc(100% - 25px);
    }
    .customizer-contain .customizer-body .layout-types {
        margin-bottom: -30px;
    }
    .customizer-contain .customizer-body .layout-types > li {
        display: block;
        width: unset;
        height: unset;
        margin-bottom: 30px;
    }
    .customizer-contain .customizer-body .layout-types > li .layout-img h6 {
        padding-top: 0;
        text-transform: inherit;
        text-align: center;
    }
    .customizer-contain .customizer-body .layout-types .box-layout {
        background: unset;
    }
    .layout-grid:not(.customizer-color) li {
        padding: 5px;
    }
    .layout-grid:not(.customizer-color) li > .header {
        height: 9px;
        border-radius: 3px;
        position: relative;
    }
    .layout-grid:not(.customizer-color) li > .header ul {
        position: absolute;
        top: -8px;
        left: 5px;
    }
    .layout-grid:not(.customizer-color) li > .header ul > li {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 5px;
        top: calc(50% - 3px);
        background-color: var(--theme-deafult);
        margin-right: 2px;
        padding: 0;
    }
    .layout-grid:not(.customizer-color) li > .header ul > li:nth-child(2) {
        background-color: var(--theme-secondary);
    }
    .layout-grid:not(.customizer-color) li > .header ul > li:nth-child(3) {
        background-color: #51bb25;
    }
    .layout-grid:not(.customizer-color) li > .body {
        background-color: #f4f4f4;
        height: calc(100% - 16px);
        border-radius: 3px;
        position: relative;
        margin-top: 5px;
        text-align: center;
        line-height: 3.1;
    }
    .layout-grid:not(.customizer-color) li > .body ul {
        height: 100%;
        background-color: #fff;
    }
    .layout-grid:not(.customizer-color) li > .body ul .sidebar {
        width: 18px;
        height: 100%;
        border-radius: 3px;
        display: inline-block;
        margin-right: 3px;
        padding: 0;
    }
    .layout-grid:not(.customizer-color) li > .body ul .sidebar.compact {
        width: 12px;
    }
    .layout-grid:not(.customizer-color) li > .body ul .sidebar.compact ~ .body {
        width: calc(100% - 19px);
    }
    .layout-grid:not(.customizer-color) li > .body ul .body {
        width: calc(100% - 25px);
        padding: 0;
        margin: 0;
        height: 100%;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 3px;
    }
    .layout-grid:not(.customizer-color) li > .body ul .body .badge {
        position: absolute;
    }
    .jsgrid-filter-row select,
    .jsgrid-filter-row input {
        padding: 6px 10px;
        border: 1px solid #f2f4ff;
        background: #fff;
    }
    .popover {
        background-color: #fff;
        border: none;
        -webkit-box-shadow: 0 0 20px rgba(115, 102, 255, 0.1);
        box-shadow: 0 0 20px rgba(115, 102, 255, 0.1);
    }
    .popover .popover-header {
        background-color: #eae8ff;
        color: #03565a;
        border-bottom: none;
    }
    .popover .popover-body {
        color: rgba(43, 43, 43, 0.7);
    }
    .tooltip.bs-tooltip-top .tooltip-arrow:before {
        border-top-color: #eae8ff;
    }
    .tooltip.bs-tooltip-bottom .tooltip-arrow:before {
        border-bottom-color: #eae8ff;
        border-top-color: transparent;
    }
    .tooltip.bs-tooltip-start .tooltip-arrow:before {
        border-left-color: #eae8ff;
        border-top-color: transparent;
    }
    .tooltip.bs-tooltip-end .tooltip-arrow:before {
        border-right-color: #eae8ff;
        border-top-color: transparent;
    }
    .tooltip .tooltip-inner {
        background-color: #eae8ff;
        color: #03565a;
    }
    .tooltip .tooltip-arrow:before {
        border-top-color: #eae8ff;
    }
    .dropdown-basic .dropdown .dropdown-content a {
        padding: 6px 16px;
        color: #2b2b2b;
        opacity: 0.6;
        font-size: 13px;
        border-top: 1px solid #efefef;
        background: #fff;
    }
    .dropdown-basic .dropdown .dropdown-content a:hover {
        background-color: #fff;
    }
    .dropdown-basic .dropdown .dropdown-content .dropdown-header {
        padding: 8px 16px;
        font-weight: 400;
        color: #2c323f;
        font-size: 13px;
    }
    .dropdown-basic .dropup .dropup-content {
        top: auto;
    }
    .default-according .card .card-header {
        padding: 0;
        border: none;
        border-radius: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .default-according .card .card-header.bg-primary .btn,
    .default-according .card .card-header.bg-secondary .btn {
        border-color: rgba(0, 0, 0, 0);
    }
    .default-according .card .card-header i {
        position: initial;
        font-size: 20px;
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: 5px;
    }
    .default-according .card .card-header h5 {
        margin-top: 0;
    }
    .default-according .card .card-header .btn-link {
        padding: 12px 20px;
        width: 100%;
        text-align: left;
        letter-spacing: 0.7px;
        font-family: Roboto;
        border: 1px solid #efefef;
        border-radius: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .default-according .card .card-body {
        padding: 15px 20px;
        line-height: 22px;
        font-size: 13px;
        border-color: #efefef;
        border-radius: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        color: #2c323f;
    }
    .nav-tabs {
        border-bottom-color: #efefef;
    }
    .nav-tabs .nav-bottom .nav-item .nav-link.active {
        border-color: #efefef;
    }
    .nav-tabs .nav-item.show .nav-link {
        font-weight: 500;
    }
    .nav-tabs .nav-link {
        color: #2c323f;
    }
    .nav-tabs .nav-link.active {
        font-weight: 500;
    }
    .nav-tabs.nav-bottom .nav-item .nav-link.active {
        border-color: #ecf3fa #ecf3fa #fff;
    }
    .dropdown-menu {
        -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
        box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
        border: none;
        padding: 0;
    }
    .dropdown-menu .dropdown-item {
        color: #2b2b2b;
        opacity: 0.6;
        font-size: 13px;
        padding: 6px 12px;
        border-top: 1px solid #efefef;
        background: #fff;
    }
    .dropdown-menu .dropdown-item:hover {
        background-color: #fff;
    }
    .dropdown-menu .dropdown-divider {
        margin: 0;
        border-top: 1px solid #cccccc;
    }
    .border-tab .nav-tabs .nav-item .nav-link.active {
        border-bottom: 2px solid #03565a;
    }
    .border-tab .nav-tabs .nav-item .nav-link.show {
        border-bottom: 2px solid #03565a;
    }
    .border-tab .nav-tabs .nav-item .nav-link:focus {
        border-bottom: 2px solid #03565a;
    }
    .tabbed-card .nav-tabs .nav-item .nav-link {
        top: 17px;
    }
    .timeliny .timeliny-dot::before {
        font-size: 18px;
    }
    .form-builder-header-1,
    .form-builder-2-header {
        background-color: #f7f6ff;
    }
    .form-builder-header-1 .nav-primary .nav-link,
    .form-builder-2-header .nav-primary .nav-link {
        color: #2c323f;
        font-weight: 400;
    }
    .form-builder-header-1 .nav-primary .nav-link.active,
    .form-builder-2-header .nav-primary .nav-link.active {
        color: #fff;
    }
    .form-builder-header-1 h6,
    .form-builder-2-header h6 {
        margin-bottom: 0;
        font-weight: 400;
        line-height: 36px;
    }
    .component .input-group .btn {
        line-height: 32px;
        text-transform: capitalize;
    }
    .form-builder .drag-box fieldset {
        margin-top: 0;
    }
    .login-card .login-main .theme-form label {
        font-size: 14px;
    }
    .login-card .login-main .theme-form .show-hide {
        top: 50%;
    }
    .faq-accordion .card .btn-link svg {
        margin-left: 20px;
    }
    .job-filter .faq-form .form-control {
        font-size: 14px;
    }
    .job-filter .faq-form .search-icon {
        width: 16px;
        height: 16px;
    }
    
    /* ------------------------------------- */
    /* .left-header ul li svg {
        fill: #fff;
    }
    
    ul.nav-menus {
        margin-top: 12px;
    }
    
    .card1{
        padding: 10px;
        background: #fff ;
    }
    .bg-primary1 th{
        background-color: transparent !important;
        color: #fff !important ;
    }
    .css-1uu34zr, .css-b8tju9, .MuiBox-root.css-1l9ohcm{
        border: none !important;
        border-radius: 10px;
    }
    .btn-primary1{
        padding: 10px 25px !important;
    }
    .btn-primary1:hover{
        color: #fff !important;
    }
    .form-control, input[type=text]{
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #696f79 !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.25rem;
        min-height: 47px !important;
        width: 100%;
    padding: 16px 10px;
    font-size: 18px;
    font-family: 'DM Sans';
    margin-top: 10px;
    display: inline-block;
    }
    input[type=text]{
        font-size: 15px !important;
    }
    .form-select {
       
        display: block;
        width: 100%;
        padding: 0.375rem 1.75rem 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        vertical-align: middle;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    } */