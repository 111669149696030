.table-custom thead{
    height:90px;
    background: #F8FAFB;

}

.table-custom th{
    border: 1.5px solid #D9D9D9; 
    background: #F8FAFB;    
    border-top: none;
    border-bottom: none;
    color: black;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}


.table-custom th:first-child{
    border-left: none;
    text-align: left;
    width: 50%;
}
.table-custom th:nth-child(2){
    text-align: left;
    width: 10%;
}
.table-custom th:nth-child(3){
    text-align: left;
    width: 20%;
}
.table-custom th:last-child{
    text-align: left;
    width: 20%;
    border-right: none;
}

.table-custom td{
    border: 1.5px solid #dddddd; 
    height: 35px;
    border-top: none;
    border-bottom: none;   
    color: black;
    background-color: white;
}
.table-custom td:first-child{
    border-left: none;
    text-align: left;
    padding-left: 1rem;    
    width: 50%;
}
.table-custom td:nth-child(2){    
    text-align: left;
    padding-left: 1rem;
    width: 10%;
}
.table-custom td:nth-child(3){    
    text-align: left;
    padding-left: 1rem;
    width: 20%;
}
.table-custom td:last-child{
    text-align: left;
    /* padding-left: 1rem; */
    width: 20%;
    border-right: none;
}

.adjustment-p{
    background-color: #03565A;
    color: white;
    padding: 0.5rem;
    border-radius: 5px;
}

/* .tooltip-hoverIcon{
    cursor: pointer;
    color: #b3b3b3;
    filter: drop-shadow(0px 4px 3px #dddddd);
}
.tooltip-hoverIcon:hover{
    color: #999;
    transform: scale(1.1) translateZ(0px);
    transition: 0.35s ease-in-out;
    filter: drop-shadow(0px -2px 5px #dddddd);
} */

.adjustment-shadow {
    box-shadow: 0px 1px 4px 1px #b3d2d4;
}

.adjustment-border {
    border: 1px solid #03565a;
}
.adjustment-border:focus{
    box-shadow: #b3d2d4 0px 2px 5px -1px, #03565a 0px 1px 3px -1px !important;
}