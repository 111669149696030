#TemplateScroll::-webkit-scrollbar {
    width: 10px;
  }
   
#TemplateScroll::-webkit-scrollbar-track {
box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
   
#TemplateScroll::-webkit-scrollbar-thumb {
    background-color: #03565A;
    outline: 1px solid white;
  }

  /* .wrapper {
    border: 3px solid;
  } */
  
  /* .container {
    background-color: pink;
  } */
  
  /* .barCompleted {
    background-color: red;
    width: 80%;
  }
  
  .label {
      font-size: 20px;
      color: green;
    } */