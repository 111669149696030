/* Scroll top tab */
.top-btn {
  width: 3rem;
  height: 3rem;
  color: #03565a;
  /* background-color: #fff; */
  border-radius: 50%;
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );

  transition: transform 0.3s ease-in-out;
}

.top-btn:hover {
  transform: scale(1.1);
}

/* Primary button */
.primary-button {
  box-shadow: #b3d2d4 0px 2px 5px -1px, #03565a 0px 1px 3px -1px !important;
  transition: box-shadow 0.3s, transform 0.3s;
}

.primary-button:hover {
  box-shadow: #66a2a4 0px 4px 10px -1px, #03565a 0px 2px 5px -1px !important;
  transform: scale(1.01) translateY(-0.5px);
  transition: 0.15s ease-in-out;
}

/* Tool tip */
/* CommonToolTip.css */
.commontooltip {
  position: relative;
  display: inline-block;
}

.commontooltip .commontooltiptext {
  visibility: hidden;
  width: fit-content;
  min-width: 150px;
  text-align: center;
  padding: 5px;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  font-weight: 500;
  opacity: 0; 
  transition: opacity 0.3s ease-in-out;
}

.commontooltip:hover .commontooltiptext {
  visibility: visible;
  opacity: 1; 
}

/* Variants */
.primary {
  color: #6e6e6e;
  background: rgba( 219, 210, 225, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 5px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.error {
  color: #ff4757; 
  background: rgba( 255, 71, 87, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 5px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.success {
  color: green;
  background: rgba( 46, 213, 115, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 5px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.warning {
  color: #ffa502;
  background: rgba( 255, 213, 138, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 5px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

/* Placement */
.top {
  bottom: 100%;
  left: -85%;
  transform: translateX(-50%);
}

.bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.left {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
}

.right {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}