.table-row{
    width: 10%;
    text-align:end;
    padding-right: 1rem;
}
.table-row-input{
    border: none;
    text-align: end;
    background-color: inherit;
}
.table-row-header-input{
    border: none !important;
    padding: 0px !important;
    text-align: start;
    background-color: inherit;
}
.table-row-header-total {
    text-align: start;
    padding-left: 2rem;
}

.table-row-input-1{
    width: 5rem;
    border: none;
    text-align: end;
    background-color: inherit;
}
.table-row-header-input-1{
    width: 5rem;
    border: none !important;
    padding: 0px !important;
    text-align: start;
    background-color: inherit;
}

.table-row-calculate-total {
    background-color: #f0dbc8;
    font-weight: 600;
}

.table-row-sub-total {
    width: 10%;
    text-align:end;
    padding-right: 2rem;
}