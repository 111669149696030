.save-button {
    width: 150px;
    height: 40px; 
}
.import-button {
    text-transform: none !important ;
    width: 265px;
    height: 40px;
    border: 2px solid #03565A !important ;
}
.preset-button {
    border-color: 2px solid #03565A;
    width: 200px;
}

.handle-scroll {
    max-height: 300px;
    overflow-y: auto;
}
.pybox{
    max-width: 100%;
    padding-left: 100px;
}