.checked .custom-checkbox:before {
    background-color: green;
}

.finalise-textcolor {
    background-color: #F8F8F8;
}

.custom-redcolor {
    color: red;
}

/* styles.css */
.coustom-blucolor {
    color: #4061FF;
}

.coustom-color {
    background-color:#D6D6D6 ;
}
.coustom-textalign{
    text-align: start;
}
.card-radious{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    
}