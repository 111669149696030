

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* border-radius: 10px; */
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* border-radius: 10px; */
}
 
::-webkit-scrollbar-thumb {
  background-color: #03565A;
  /* outline: 1px solid white; */
  /* border-radius: 10px; */
}

.react-date-picker.react-date-picker--closed.react-date-picker--enabled{
  /* width: 100% !important; */
  background: #fff;
}

.disabled-table{
  background-color: rgba(236, 240, 241, 0.5);
 pointer-events: none;
}

/* Updated checkbox default color */
input[type='checkbox'] {
  accent-color: #03565A !important;
  cursor: pointer;
}
/* Custom CSS */
.cursor-pointer{
  cursor: pointer !important;
}
.primary-border{
  border: 1px solid #03565A !important;
}
.custom-border{
  border: 2px solid #03565A !important;
}
.custom-border-gray{
  border: 1px solid #D6D6D6;
}
.custom-common-theme-text-color{
  color: #03565A !important;
}
.custom-common-theme-bg-color{
  background-color: #03565A !important;
}
.bg-secondary-400{
  background-color: #A2C0C2 !important;
}
.custom-common-secondary-bg{
  background-color: #E5EEEE !important;
}
.bg-secondary-100{
  background-color: #E9E9E9 !important;
}
.bg-warning-100 {
  background-color: #f8fac9 !important;
}
.custom-bg-warning {
  background-color: #f2e2b5;
}
.custom-bg-warning-200 {
  background-color: #F0DBC8 !important;
}
.bg-warning-400{
  background-color: #FDC27B !important;
}
.custom-bg-blue-200 {
  background-color: #F1F5F9;
}
.bg-light-blue {
  background: #D1E0E0;
}
.custom-bg-white-150 {
  background-color: #F8F8F8;
}
.custom-bg-white-200 {
  background-color: #F8FAFB;
}
.no-shadow {
  box-shadow: none !important;
}
.custom-bg-gray-200 {
  background-color: #e0e0e0;
}
.custom-bg-gray-300 {
  background-color: #F1F2F5;
}
.custom-bg-gray-100 {
  background-color: #f5f5f5;
}

.custom-bg-accordion {
  background-color: #D6D6D6  ;
}

.bg-color-inherit {
  background-color: inherit;
}
.custom-font-color{
  color: #717171  ;
}

.custom-text-color{
  color: #D6D6D6;
}

.custom-text-black{
 color: #31354A;
}


.font-size-12 {
  font-size: 12px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-20 {
  font-size: 20px !important;
}
.font-size-24 {
  font-size: 24px !important;
}

.custom-font-500{
  font-weight: 500 !important;
}
.custom-font-600{
  font-weight: 600 !important;
}
.custom-font-700{
  font-weight: 700 !important;
}

.radius-6px {
  border-radius: 6px !important;
}
.radius-8px {
  border-radius: 8px !important;
}
.radius-10px {
  border-radius: 10px !important;
}
.radius-12px {
  border-radius: 12px !important;
}
.radius-16px {
  border-radius: 16px !important;
}

.border-gray-300 {
  border: 1px solid #8692A6 !important;
}

.pr-2rem{
  padding-right: 2rem;
}
.pl-2rem{
  padding-left: 2rem;
}

.tooltip-icon {
  color: #ff9800;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}
.tooltip-icon:hover {
  color: #ee8e00;
  transform: translateY(-1px);
}

/* End Custom CSS */
/*! Sweet alert color */
.swal-button{
  background-color: #03565A !important;
}
.swal-button:not([disabled]):hover{
  background-color: #04666A !important;
}
.swal-button--cancel{
  color: white;
}

.back-btn:hover{
  transform: scale(1.04) translateZ(0px);
  text-shadow: 0px 4px 8px #05959C;
  transition: 0.35s ease-in-out;
}

.table_td table tr td{
  border: none;
}

/* new material Ui css */
/* label[data-shrink=false]+.MuiInputBase-formControl .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  border: none !important;
}

.css-cev4hm-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0.5px 4px 7.5px 5px;
  border: none !important;
} */

/*@ashsis Overwrite mui css tab */
/*  */
/* .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 100vw !important;
} */

@media screen and ( max-width : 1300px ){


  .RzCDL {
      margin-left: calc(54vw);
  }
  .cUbfEr {
      margin-left: calc(54vw);
  }
  .nav-tabs .nav-link {
      font-size: 14px;
  }
  .ck.ck-reset.ck-editor.ck-rounded-corners {
      width: 60%;
  }
  .tableSheet table {
      width: 60%;
  }
  .tableSheet table {
      width: 60%;
  }
  .containerQuestionnaire {
  
      margin: 0 auto;
      min-width: 84%;
  }
  
  
  }

  /* .Mui-checked {
    color: #03565A !important;
} */