
.previewCard{
    /* height:15rem; */
    width:100%;
    background-color: #FDFDFD;
    border-radius: 6px;
    padding: 2rem;
    cursor:pointer;
    position: relative;
    
}

.previewCard:hover{
    box-shadow: 2px 2px 10px #5858584b ;
    transition: ease-out;
    transition: 300ms;
}
.previewCardv2{
    /* height:15rem; */
    width:100%;
    background-color: #F2F3F4 ;
    border-radius: 6px;
    padding: 2rem;
    cursor:pointer;
    position: relative;
    
}

/* .previewCardv2:hover{
    box-shadow: 2px 2px 10px #5858584b ;
    transition: ease-out;
    transition: 300ms;
} */

#gridScroll{
    display:grid;
    grid-template-columns:repeat(4, 23%);
    grid-gap:2rem;
    margin:1rem 0;
    max-height:70vh;
    overflow-y:scroll;
    padding:10px 2rem 0;
}


@media (max-width: 1100px){
    #gridScroll{
        grid-template-columns: repeat(3, 30%);
    }
}