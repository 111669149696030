
.custom-container {
  width: 300px;
  height: 100%;
  background-color: white;
}

/* table,th,td,thead{
  border: none !important;
}
td,th{
  text-align:justify
} */
