/* .socie-table-container {
    overflow-x: auto;
}

.socie-responsive-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.socie-responsive-table th,
.socie-responsive-table td {
    text-align: left;
    padding: 8px;
    border: 1px solid #ddd;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 768px) {

    .socie-responsive-table th,
    .socie-responsive-table td {
        white-space: normal;
    }
} */
.socie-header{
    min-width: 320px;
}
.socie-amount-header{
    min-width: 160px;
}