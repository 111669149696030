table {
  border-collapse: separate;
  border-spacing: 0;
  font-family: 'DM Sans';
  width: 100%;
  }
 
  
  
td {
    border: 1px solid #dddddd;
    text-align: center;
    /* padding: 10px; */
    /* font-size: 16px; */
    /* color: var(--clr-font-mid); */
    height: 45px;
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  }
th{
  border: 1px solid #dddddd;
  text-align: center;
  padding: 10px;
  /* background-color: #dddddd79; */
  color: rgb(71, 70, 70);
  font-size: 16px;
  height: 45px;
}

tr:hover{
  background-color: rgba(105, 122, 117, 0.062);
}

thead{
  
}


/* option {
  padding: 10px;
  font-size: 16px;
  background-color: beige;
}

option:before {
  content: ">";
  font-size: 20px;
  display: none;
  padding-right: 10px;
  padding-left: 5px;
  color: rgb(129, 59, 59);
} */


.form-control {
  text-overflow: ellipsis;
  white-space: nowrap;  
  overflow: hidden;
  color: grey;
  background-color: transparent;

  outline: none;
  border: none;
  border-radius: 0rem;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  height: 15px;
  width: 15px;
  margin: 10px;
}

#containerDiv::-webkit-scrollbar {
  width: 10px;
}
 
#containerDiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
 
#containerDiv::-webkit-scrollbar-thumb {
  background-color: #03565A;
  border-radius: 10px;
}

.tableDeleted{
  max-height: 17rem;
  min-height: 17rem;
  overflow-y: auto;
}