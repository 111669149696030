.lease_sidebar {
    height: 100vh;
    width: 300px;
    background-color: white;
}

.ne_sidebar {
    display: flex;
    justify-content: space-between;
    width: 85%;
}

.ne_sidebar>.ne_btn {
    background-color: #5CC8BC;
    border-radius: 10px;
    color: white;
    height: 60px;
    width: 50px;
    margin-top: 20px;
    text-transform: none;

}

.ne_sidebar>.ne_btn:hover {
    background-color: #5CC8BC;


}

.department_v1 {
    display: flex;
    background-color: #f5f5f5;
    border-radius: 10px;
    margin-top: 10px;
    padding: 13px;
    width: 85%;
    margin-left: 20px;

}

.leasehead{
    
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    margin-left: 33px;
}
.leaseh5div{
    margin-left: 11px;
   
}
.leaseh5{
 font-weight: 700;
    font-size: 24px;
    line-height: 28.8px;
    color: #000000;
}
.leasep{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color:#7E7E7E;
}
.leasepie1{
    font-weight: 700;
    font-size: 18.07px;
    line-height: 28.11px;
    color: #000000
}
.accounthead{
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
}
.ac-t-head{
    font-weight: 600;
    font-size: 12px;
    line-height: 14.52px;
}
.ac-t-content{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color:#000000;
    
}
.createstep{
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #929292;
}
.createhead{
    font-weight: 700;
    font-size: 28px;
    line-height: 33.6px;
}

.create-para{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #939393;
}

.createsel{
    min-width: 426px;
    margin-left: 2px;
    margin-top: -11px;
    height: 61px;
    border-radius: 5px;
}
.create-inp-lab{
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #696F79;
    margin-left: 3px;
   margin-top: 14px;
}

.create-inp{
    /* width:780px; */
    margin-top: -3px;
}
.createbtn{
    
     margin-top: 123px;
}
.fin-main-inp-w{
    max-width: 60rem;
}
.fin-lab-div{
    margin-top: 24px;
}
.fin-inp-div{
   
        min-width: 426px;
        border: 1px solid gray;
        border-radius: 6px;
        background-color: white;
        margin-top: 12px;
     
}
.fin-lab{
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #696F79;
 margin-top: 12px;
}
.fin-drop{
    margin-top: 22px;
}
.fin-acco-dv{
 min-width: 910px;
 border-radius:5px !important;
}
.fin-acco-head{
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    color: #000000 !important;
}
/* input[type=date]{
    color: transparent !important;
} */
/* input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
    display: none !important;
    align-items: flex-end !important;
    float:right !important
   
} */
/* input[type=date]:required:invalid::-webkit-datetime-edit {
    color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
} */