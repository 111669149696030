.lease-register-hover:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.09) -1px 1px 4px
}

.category-assets {
    min-height: 350px;
    height: fit-content;
    background-color: white;
    border-radius: 10px;
}

.link-icon>.calndr {
    margin-right: -45px;
    height: 16px;
    width: 16px;
}

.link-iconn>.arrow-icon {
    margin-left: -45px;
    height: 30px;
    width: 30px;
}

.i-con>.drag-icon {
    width: 27px;
    height: 40px;
    color: #DFE2E7;

}

.preset-name {
    gap: 5px;
    margin-top: -30px;
}

.preset-name>.aprt-icon {
    width: 14px;
    height: 14px;
    color: #ABA9A9;

}

.preset-name>.company-text {
    line-height: 22px;
    color: #ABA9A9;

}

.preset-name>.cal-icon {
    width: 14px;
    height: 14px;
    color: #ABA9A9;

}

.dashed-border {
    border: 2px dashed #D6D6D6;
    border-radius: 12px;
}
/* 
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 7px ;

} */

.header__center {
    font-size: 2rem;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 1.2rem;
    align-items: center;
    color: #C3C8D3;
    padding: 5px;
}

.header__center::before,
.header__center::after {
    content: "";
    display: block;
    height: 1px;
    background-color: currentColor;
}

.land-text>.land-card {
    height: 75px;
    background-color: #F8F8F8;
    border: 2px solid #ABA9A9;
    border-radius: 10px;
}

.category-assets>.enter-class {
    background-color: #D6D6D6;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.financials-data {
    max-height: 80vh;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* new css */
.primaryy-border {
    border: 2px solid #03565A !important;
}

.header {
    background-color: #D1E0E0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.lease-table {
    background-color: #F8F8F8;
}

.lease-icon {
    color: #DFE2E7;
}

.custom-color {
    color: #ABA9A9;
}
.custom-textcolor {
    color: #717171;
}
.card>.import-card{
    border-radius: 12px;
    border: 2px solid #D6D6D6 ;
}
.addtext{
    margin-top: -34px;
}


/* @CSS for Side accordian */
.link-pl-35px{
    padding-left: 35px !important;
}

input[placeholder="MM/DD/YYYY"] {
    padding-left: 35px !important;
}

/* @tag-assets */
.tag-assets-options {
    min-height: 70vh;
    height: 70vh;
    overflow-y: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.tag-assets-option-hr{
    width: 40%;
    border-top: 2px solid #C3C8D3;
}
.border-radius-t-lf-10{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.tag-assets-option{
    box-shadow: -3px 3px 40px 0px #00000014;

    min-height: 55px;
}
.register-here{
    width: 224px;
}



/* @handle scroll */
.link-register-side-bar{
    height: fit-content;
    max-height: 75vh;
    overflow: auto;
}