#list::-webkit-scrollbar {
    width: 5px;
  }
   
  #list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
   
  #list::-webkit-scrollbar-thumb {
    background-color: #03565A;
    border-radius: 10px;
  }