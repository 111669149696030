.financialInstrument-deleteIconHover{
    filter: drop-shadow(0px 3px 2px #999);
}

.financialInstrument-deleteIconHover:hover{
    color: #f33;
    filter: drop-shadow(0px -2px 3px #F66);
    transform: scale(1.2) translateZ(0px);
    transition: 0.35s ease-in-out;
}
.financialInstrument-restoreIconHover{
    color: #00b327;
}
.financialInstrument-restoreIconHover :hover{
    transition: 0.10s ease-in-out;
    filter: drop-shadow(0px -2px 5px #2bff59);
}
.financialInstrument-hoverIcon{
    filter: drop-shadow(0px 4px 3px #FDE507);
}
.financialInstrument-hoverIcon:hover{
    color: #dd7900;
    transform: scale(1.1) translateZ(0px);
    transition: 0.35s ease-in-out;
    filter: drop-shadow(0px -2px 5px #FDE507);
}

.deleteBtn{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    cursor: pointer;
}
.deleteBtn:hover{
    transform: scale(1.01) ;
    transition: 0.15s ease-in-out;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 3px 0px, rgba(0, 0, 0, 0.3) 1px 2px 0px -1px;
}

.financial-instrument-note{
    color: #04666a;
    font-weight: 600;
    cursor: pointer;
    font-size: 1rem;
}
.financial-instrument-note:hover{
    font-size: 1.03rem;
    transition: 0.10s ease-in-out;
    text-shadow: 0px 0px 1px #03565a;
}

.financial-restore-modal{
    /* text-align: center !important; */
    font-size: 1.5rem !important;
    color: #03565a !important;
}