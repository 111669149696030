.adjustmentsMenu{
    display: flex;
    align-items: center;
    height: 58px;
    position:relative;
    background-color: var(--clr-bg);
    box-shadow: 4px 4px 8px #8b8b8b38 ;
    /* -webkit-box-shadow:4px 4px 8px #504f4f5d; */
    padding: 0 1rem 0 1rem;
    z-index: 1;
    position: sticky;
    top: 80px;
    min-width: 1045px;
}

.adjustmentsMenu div{
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
    cursor:pointer;
}

.adjustmentsMenu div p{
    margin-top: 16px;
    margin-left: 2px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    text-align: left;

}

@media (max-width: 1100px) {
    .adjustmentsMenu div p{
        display: none;
    
    }
    .adjustmentsMenu  div{
        margin: 1rem;
    }

    
  }

  .adjustmentsMenu div:hover{

    text-decoration: underline;
}



.adjustmentsTable th{
    border: 1px solid #dddddd;
    text-align: center;
    padding: 10px;
    background-color: #F8F8F8;
    color: #717171;
    font-size: 14px;
    height: 62px;
    font-weight: 600;
    position: sticky;
    top:0
  }
.adjustmentsTable tr{
    /* padding: 10px; */
    font-size: 14px;
    /* height: 62px; */
    font-weight: 400;
    color: black;

  }
.adjustmentsTable td{
    /* padding: 10px; */
    font-size: 17px;
    /* height: 62px; */
    font-weight: 400;
    color: var(--clr-font-mid);

  }


  .adjustmentsTable::-webkit-scrollbar {
    width: 10px;
  }
   
  .adjustmentsTable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  .adjustmentsTable::-webkit-scrollbar-thumb {
    background-color: #03565A;
    outline: 1px solid white;
  }

  /* new adj */
  .records {
    width: 100%;
    display: flex;
    height: 35px;
    background-color: lightgray;
    border: 2px solid #D0D0D0;
    align-items: center;
    padding: 0 1rem;
    position: absolute;
    bottom: 0;
  }