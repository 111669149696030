.Main_div {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
}

.Main_div>.Main_Card {
    width: 406px;
    height: 350px;
    background-color: #D1E0E0;
    border-radius: 12px;
}

.Img_Style {
    width: 262px;
    height: 254px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.lock_btn {
    position: relative
}

.lock-profile {
    height: 100%;
    Position: absolute;
    top: 0;
    padding-top: 80%;
    width: 100%;
    padding: 114px;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
}

.Scnd_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 80px;
    width: 408px;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
    box-shadow: none !important;
}

.px_25 {
    padding: 10px 25px;
}

.Main_card {
    display: flex;
    justify-content: space-between;
    background-color: white;
    height: 100%;
    width: 100%;
    padding-top: 23px;

}

.css-1cuxlhl {
    display: none !important;
}

.p-table {
    font-weight: 800;
    font-size: 16px;
    color: #03565A;
}

.p-secondtable {
    color: gray;
    text-align: left;
    margin-left: 12px;
    margin-top: 9px;

}

.p-thirdtable {
    color: black;
    text-align: left;
    margin-left: 12px;
    margin-top: 9px;

}

.css-10d9dml-MuiTabs-indicator {
    left: 0 !important;
    width: 5px !important;
    background-color: inherit !important;
}

.form {
    margin-top: 10px;
    width: 200px;

    position: relative;
}

.form_v1 {
    width: 150px;

    position: relative;

}

.form .fa-search {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #9ca3af;
}

.left-pan {
    padding-left: 7px;
}

.left-pan i {
    padding-left: 10px;
}

.form-input {

    height: 55px;
    text-indent: 33px;
    border-radius: 10px;
}

.form-input:focus {
    box-shadow: none;
    border: none;
}

.form-control {
    /* background-color: white; */
}

.search_box{
    position: absolute;
    top: 15px;
    left: 10px; 
}
.search_bttn {
    position: absolute;
    top: 20px;
    margin-left: 10px;
}

.arrow-right {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    margin-top: 4px;
    border-left: 8px solid #03565A;
}

.table_culmn {
    text-align: center;
    /* line-height: 148px; */
    min-width: 6rem;
    background-color: #F2F2F2;
}

.intro {
    font-weight: 700;
    font-weight: 25px;
    font-style: normal;
    line-height: normal;
    color:black;
}

.intro_title {
    font-size: 16px;
    font-weight: 500;
    color: black;
}
.scope{
    font-weight: 700;
    font-size: 22px;
    color: #03565A;
}
.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    border-radius: 15px;
}

.text-700{
    font-weight: 700 !important;
}
.content-sidebar{
    background-color: #CDDDDE;
    max-height: 40rem;
    position: fixed !important;
    top: 4.7rem;
    overflow-y: auto;
}
.content-children{
    position: relative;
    width: 80%;
    left: 17%;
    top: 5rem;
}
.content-header{
    width: 100%;
    height: 75px;
    background-color: #03565A;
    position: fixed;
    z-index: 1;
}

.lib-data-section{
    color: black;
    font-weight: 400;
    font-size: 1rem;
    text-decoration: none !important;
    text-decoration-color: transparent !important;
}