.css-1cuxlhl {
    display: none !important;
}

.labelh1{
    font-weight: 600;
    font-size: 18px;
}

.p-table{
    font-weight: 800;
    font-size: 16px;
    color: #03565A;
}
.p-secondtable{
    color: gray;
    text-align: left;
    margin-left: 11px;
    margin-top: 9px;   
}

.p-thirdtable{
    color: black;
    text-align: left;
    margin-left: 12px;
    margin-top: 9px;
}

.light-blue{
    background-color: #CDDDDE;
}

.tb-panel{
    max-height:36rem;
    overflow-y: auto;
}

.add-remark{
    font-size: x-small;
    font-weight: 300;
}