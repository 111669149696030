.preset-section {
    margin-top: 1.5rem;
    border: 2px dashed #D6D6D6;
    border-radius: 10px;
    height: 18rem;
    cursor: pointer;
    background-color: #F8F8F8;
}

.preset-drop {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.drop-folder {
    width: 45px !important;
    height: 35px !important; 
    color: #573E20;
}

.drop-border {
    border: 2px dashed #03565A;
    border-radius: 10px;
    background-color: #EBF2F3;
}

.include-value {
    font-weight: 400;
    font-size: 1rem;
}

.include-value-d {
    font-weight: 200;
    font-size: 1rem;
}

/* MUI select */
.MuiMenu-list {
    display: flex;
    flex-direction: column;
}

/* new css */
.border-color {
    border: 2px solid #03565A;
}
.custom-card{
    border: 2px solid #D6D6D6;
}
.preset-map-div {
    height: 184px;
    overflow-y: auto;
}

.scroll-y-preset {
    height: 33rem;
    overflow-y: scroll;
}