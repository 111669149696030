.sidebar_v0 {
  width: 300px;
  height: 100vh;
  background-color:white;
 
}
.fs_v021{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
  margin-left: 20px;
}
.fs_v021>.fsbtn_v00{
  height:60px !important;
  width: 50px !important;
  background-color:#48C9B0 ;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 20px;
}
.department{
  display: flex;
  background-color:#f5f5f5 ;
  color:black;
  border-radius: 10px;
  margin-top: 10px;
  padding: 13px;
  width: 85%;
  margin-left: 20px;
}

.department_inactive{
  display: flex;
  background-color:white ;
  color: #8B909A;
  border-radius: 10px;
  margin-top: 10px;
  padding: 13px;
  width: 85%;
  margin-left: 20px;
}

.fs_v021>.fsbtn_v00:hover{
  background-color:#48C9B0 ;
}

.fstitle_v4{
  margin-left: 20px;
  margin-top: 20px;
}

/*  */
.workspace_v3{
display: flex;
flex-direction: row;
}
.workspace_v3>.circle_V3 {

height: 30px;
width: 30px;
border-radius: 15px;
border: 2px solid #03565A;

text-align: center;
}
.vl_V3 {
border-left: 2px solid #D4D8D6;
height: 15px;
margin-left: 14px;
}
.Layout_v3{

font-size: 0.9rem;
margin-top: 4px;
color:black;
margin-left: 2vh;
font-weight: 500;
}
.Layout_v3:active {
color: #03565A;
}
.Header_v8{
margin-top: 20px;
/* margin-left:20px ; */
font-weight: 600;
}
.HeaderOne_V1{
display: flex;
flex-direction: row;
/* margin-left: 20px; */
margin-top: 10px;
/* width: 80%; */
}
.card_overview{
display: flex;
flex-direction: row;
justify-content: space-between;
width: 100%;
}
.Card_v9{
display: flex;
justify-content: center;
align-items: center;
width: 45px;
height: 45px;
border-radius: 100%;
background-color: #FED8B1;

}
.wpTeampmember>.btn_x1{
margin-top: 15px;
background-color:#03565A ;
text-transform: none;
margin-left: 15px;

}
.wpTeampmember>.btn_x2{
margin-top: 15px;
text-transform: none;
background-color:#f5f5f5 ;
color: black;

}
.wpTeampmember>.btn_x2.btn:hover{
background-color: white;

}
.text_v2{
display: flex;
flex-direction: row;
justify-content: space-between;
margin-top: 20px;


}
.importBadge{
  height: 35px;
  width: 140px;
  padding-inline: 30px;
  padding-top: 7px;
  border-radius: 10px;
  color: #00A36C;
  background-color: #F2FEFF;

}

.importBadge_1{
  height: 35px;
  width: 140px;
  padding-inline: 30px;
  padding-top: 7px;
  border-radius: 10px;
  color: orange;
  background-color:#FFF6E6;

}
.importBadge_2{
  height:35px ;
  width: 146px;
  background-color:#FFEDED ;
  padding-top: 7px;
  border-radius: 10px;
  color: red;
  padding-inline: 30px;

 
}
.importBadge_4{
  height:35px ;
  width: 146px;
  background-color:#EDFFF3;
  padding-top: 7px;
  border-radius: 10px;
  color: green;
  padding-inline: 42px;

 
}
/* .importBadge_4{
  height:35px ;
  width: 146px;
  background-color:#FFEDED ;
  padding-top: 7px;
  border-radius: 10px;
  color: red;
  padding-inline: 30px;

} */
.importBadge_3{
  height: 35px;
  width: 140px;
  padding-inline: 30px;
  padding-top: 7px;
  border-radius: 10px;
  color: #1AA260;
  background-color:#EDFFF3;
}
.Maincard_V2{
width: 300px;
height: 120px;
}
.importBadge{
height: 35px;
width: 140px;
padding-inline: 30px;
padding-top: 7px;
border-radius: 10px;
color: #00A36C;
background-color: #F2FEFF;

}

.importBadge_1{
height: 35px;
width: 140px;
padding-inline: 30px;
padding-top: 7px;
border-radius: 10px;
color: orange;
background-color:#FFF6E6;

}
.importBadge_2{
height:35px ;
width: 146px;
background-color:#FFEDED ;
padding-top: 7px;
border-radius: 10px;
color: red;
padding-inline: 30px;


}
.importBadge_4{
height:35px ;
width: 146px;
background-color:#EDFFF3;
padding-top: 7px;
border-radius: 10px;
color: green;
padding-inline: 42px;


}
/* .importBadge_4{
height:35px ;
width: 146px;
background-color:#FFEDED ;
padding-top: 7px;
border-radius: 10px;
color: red;
padding-inline: 30px;

} */
.importBadge_3{
height: 35px;
width: 140px;
padding-inline: 30px;
padding-top: 7px;
border-radius: 10px;
color: #1AA260;
background-color:#EDFFF3;

}

.not_started{
width:140px ;
height: 35px;
background-color: #F2FEFF;
padding-inline: 30px;
padding-top:7px ;
border-radius: 10px;
color:#00A36C;

}






/* workspacepreset design start */
.wpcontent{
margin-top: 23px;
}
.wprow{
margin-top:10px;
}
.wpsecondrow{
margin-top: 20px;
}
.wpTypography{
 margin-top:10px;
/* color:red; */
color:black;
font-size: 14px
}
.wpcard-pad{
  padding-bottom: 30px !important;
}
.wpTeampmember{
margin-left: 23px;
}
.wpTeampmember input::placeholder{
padding-left: 23px;

}
.badgeReject{
background-color: #FFEDED;
color: red;
}
.badgeActive{
background-color: #EDFFF3;
color:green;
}
.badgePending{
background-color: #FFF6e6;
color:#cc8000;

}

.badgenotstarted{
background-color: #EDFFF3;
color:blue;
}
.wpTableContainer{
margin-top:23px;
}
.wpSearchicon{
  margin-top: -86px;
   margin-left: 12px;
   /* padding: 16px 45px; */
}

/* end workspacepreset */

/* workspacedetails design start */
.wdContainer{
margin-top: 45px;
}
.wdlabel{
margin-top: 23px;
color: gray;
}
.wdcard{
margin-top: 23px;
}
.wdcyear{
 margin-top: 12px;
}
.wddate{
font-size:14px;
}
.wdpyear{
margin-left: 32px;
}
.wd2ndcard{
 margin-left: 70px
}
.wdButton{
margin-top: 63px;
}

.wp-h{
 
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}
.wp-sechead{
font-weight: 700;
font-size: 16.35px;
line-height: 25.43px;
width: 226px;
height: 26px; 
}
.wp-content{
  font-weight: 500;
  font-size: 14.53px;
 
}
.wp-card-content{
  font-weight: 700;
  font-size: 10.9px;
  line-height: 18.16px;
  color: gray;
}
/* End financial */
/* workspace register btn active */

/*  */

/* ashis css */
.tabtoggle{
 
 border-radius: 0px !important;
}
.css-1aquho2-MuiTabs-indicator{
  
background-color: inherit !important;
}


.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color:black;
  background-color:#03565A;
}
.css-19kzrtu {
  padding: 0 !important;
}
/* consoldation work css */
.fs_logo_1{
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background-color: orange;
  
}
.image_fs_1{
  height: 30px;
  width: 30px;
  margin-top: 34px;
  margin-left: 34px;
 

}
.consol_fs{
display: flex;
justify-content: center;
margin-top: 20px;

}
.fs_bttn_v1{
  width: 270px;
  text-transform: none;
  color: black;
  background-color: white;
  border: 2px solid green;

  
}
.one_Box{
  height:80px ;
  width: 100%;
  background-color: white;
  margin-top: 10px;

}
.wdButton>.save_btn{
  width: 150px;
  height: 40px;
  border-radius: 5px;
  text-transform: none;
  background-color: #03565A;
  color: white;
 
}
.wdButton>.save_btn:hover{
 
  background-color: #03565A;
 
 
}
/* work in workspacedateilsv2 */
.choice_date{
  margin-top: 0px;
  width: 210px;
  height: 40px;
  border: "2px solid gray";
  border-radius: 5px;
}
/* work in consolidation page */
.my_button{
  display: flex;
  justify-content: flex-end;

}
.my_button>.my_button_v1{
  margin-top: 16px;
  background-color: #03565A;
  text-transform: none;
  margin-right: 30px;
  width: 250px;
  height: 45px;
  color: white;
  border-radius: 10px;

}
.my_button>.my_button_v1:hover{
  background-color: #03565a;
 

}
.consol_fs{
}
.consol_fs>.btn_consol{
  width: 280px;
  height: 40px;
  text-transform: none;
  color:  #03565A;
  border: 2px solid green;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
 
}
.consol_fs>.btn_consol:hover{
  background-color:white;
 
 
}
.tabtoggle{
 
  border-radius: 0px !important;
 }
 .css-1aquho2-MuiTabs-indicator{
   
 background-color: inherit !important;
 }
 
 
 .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
   color: black ;
   background-color:#03565A;
 }
 .css-19kzrtu {
   padding: 0 !important;
 }
.Active_v1:active{
  background-color: red;

}

.table_btn{
  width: 150px;
  height: 40px;
  background-color: #F2FEFF ;
  border: none;
  border-radius: 5px;
  color: #2DA6AC;
}
.table_btn_v1{
  width: 150px;
  height: 40px;
  background-color: #F2FEFF ;
  border: none;
  border-radius: 5px;
  color: #2DA6AC;
}
.progress_btn{
  width: 150px;
  height: 40px;
  background-color:#FFF6E6 ;
  border: none;
  border-radius: 5px;
  color:#CC8000;
}
.identified_btn{
  width: 150px;
  height: 40px;
  background-color:#FFEDED;
  border: none;
  border-radius: 5px;
  color:#CD3030;
}
.verified_btn{
  width: 150px;
  height: 40px;
  background-color:#EDFFF3;
  border: none;
  border-radius: 5px;
  color:#068133;

}



.chek_btn>.btn_chek43 {
  width: 130px;
  text-transform: none;
  background-color: white;
  border: 2px solid #03565A;
  color: black;
  height: 40px;
  border-radius: 10px;
}

.chek_btn>.btn_chek43:hover {
  background-color: white;

}

.chek_btn>.btn_chek53v2 {
  width: 130px;
  text-transform: none;
  background-color: white;
  border: 2px solid #03565A;
  color: black;
  height: 40px;
  border-radius: 10px;
  margin-left: 10px;
}

.chek_btn>.btn_chek53v2:hover {
  background-color: white;

}