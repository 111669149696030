.sidebar_v2 {
  width: 100%;
  height: 100vh;
  /* max-height: 100%; */
  background-color: white;
  /* margin-bottom: 10px; */
}

.img-v2 {
  width: 9rem;
  height: 9rem;
  margin-left: 4vh;
}

.first1 {
  display: flex;
  flex-direction: row;
  margin-left: 5vh;
}

.first>.icon1 {
  height: 1rem;
  width: 1rem;
  margin-top: 12px;

}

.layout1 {
  font-size: 0.9rem;
  color: #03565A;
  margin-left: 2vh;



}

.layout-v2 {

  font-size: 0.9rem;
  margin-top: 4px;
  color: black;
  margin-left: 2vh;
  font-weight: 500;



}

.layout-v2:active {
  color: #03565A;
}

.workspace-v2 {
  display: flex;
  flex-direction: row;
  margin-left: 31px;
}

.workspace-v2>.circle-v2 {

  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 2px solid #03565A;
background-color: #03565A;
color: white;
  text-align: center;
}

.workspace-v2>.circle-v3 {

  height: 30px;
  width: 30px;
  border-radius: 15px;
  /* border: 2px solid #03565A; */

  text-align: center;
}

.vl-v2 {
  border-left: 2px solid #D4D8D6;
  height: 15px;
  margin-left: 46px;
}

/*  */
.main_container {
  width: 100%;
  height: 100%;

}

.toptext-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-top: 30px;
  /* margin-left:3rem; */
}

.workinghead {
  font-weight: 700;
  font-size: 28px;
  line-height: 33.6px;
  color: #111111;
  margin-left: -22px
}

.qwerty {
  border: 2px solid red;
  height: 10rem;

  border-radius: 20px;
  /* border-radius: ; */
  /* borderRadius: "20px" */


}

.card-selected {
  height: 10rem;

  border-radius: 20px;
  border: 1px solid blue;
}

.card-v2 {

  width: 100%;
  height: 10rem;
  border-radius: 10px;
}

.card-v2-border {
  /* width: 15rem; */
  height: 10rem;
  border: 2px solid #03565A;
  border-radius: 10px;
}

.card-vv2 {

  margin-top: 20px;

}

.card-v-2 {
  width: 100%;
  height: 10rem;
  border-radius: 20px;
  /* border: 1px solid blue; */
 
}

.maincard-v2 {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 58rem;
  margin-top: 15px;

}

.chekbox-v2 {
  margin-left: 8px;

  font-size: 14px;
  color: black;
  margin-top: 5px;

}

.chekbox-v3 {
  margin-left: 10px;
  font-size: 15px;
  color: gray;
  margin-top: 20px
}

.backicon {
  display: flex;
  flex-direction: row;
  color: #03565A;
  font-weight: 550;
  margin-top: 6px;
}

.nextbtn-v2 {
  display: flex;
  justify-content: flex-end;
}

.btn-v2 {
  /* height: 40px; */
  text-transform: none;
  font-size: 1rem;
  /* width: 300px; */
  background-color: #03565A;


}

.bttn-v2 {
  height: 40px;
  text-transform: none;
  font-size: 1rem;
  width: 200px;
  background-color: #03565A;


}

.title {
  color: gray;
  font-size: 13px;
  margin-left: 3rem;
  margin-top: 10px;

}

.drop_down {
  max-height: 45vh;
  overflow-y: scroll;
  width: 100%;
}

.drop_down1 {
  margin-top: 20px;
  width: 95%;
}

.dropp_down {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn_group {
  margin-top: 0px;
}

.btn_group>.skip_btn {
  border: 2px solid #045F5F;
  margin-right: 25px;
  color: #045F5F;
  font-weight: 550;
  width: 150px;

}

.main_table {
  display: flex;
  justify-content: space-between;
  width: 94%;
  /* height: 100%; */
  margin-left: 35px;
  margin-top: 160px;
  margin-bottom: 20px;
}

.main_tablev1 {
  display: flex;
  justify-content: space-between;
  width: 94%;
  /* height: 100%; */
  margin-left: 35px;
  margin-top: 40px;
  /* margin-bottom: 20px; */
}

.title_0ne {
  color: #045F5F;
  font-size: 17px;
  font-weight: 500;
}

.hr {
  width: 76%;
  margin-left: 50px;
  margin-top: 10px;
}

/* style={{ width: "76%", marginLeft: "50px", marginTop: "40px" }}  */
/*  */
.date-v2 {
  width: 252px;
  margin-top: 8px;
  /* // style={{ width: "252px", marginTop: "8px" }} */

}

.datebtn {
  width: 252px;
  /* background-color: #03565A; */


}

.title-v2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 93%;
  margin-left: 3rem;
  margin-top: 4rem;

}

.inputbox {
  width: 75%;

}

.backicon-v2 {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  color: #03565A;
  font-weight: 550;
  margin-left: 3rem;
  margin-top: 14rem;

}

.inputbox-v2 {
  display: flex;
  /* width: 500px; */
  /* margin-left: 30px; */
  gap: 0.5rem;
  margin-top: 10px;
  background-color: white;
  border: 1px solid gray;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
  border-radius: 5px;

}

.workcercle-v2 {
  display: flex;
  flex-direction: row;
  /* margin-left: 31px; */

}

.dropdwn_v2 {
  /* display: flex; */
  /* justify-content: space-between; */
  /* width: 80%; */
}



/* import Register */


/*  */
.workcercle-v2 {
  display: flex;
  /* flex-direction: row; */
  /* margin-left: 31px; */
}

.circle-v22 {

  height: 30px;
  width: 30px;
  margin-top: 10px;
  border-radius: 100%;
  color: green;
  background-color: #c6ecc6;

  text-align: center;
}

.circle-v222 {
  height: 30px;
  width: 30px;
  margin-top: 25px;
  color: green;
  background-color: #c6ecc6;
  border-radius: 15px;
  /* border: 2px solid gray; */
  margin-left: 50px;

  text-align: center;

}

.backicon-v22 {
  /* display: flex;
  flex-direction: row;
  margin-top: 40px;
  color: #03565A;
  font-weight: 550;
  margin-left: 3rem;
  margin-top: 4rem; */

}

.nextbtn-vv2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* flex-direction: row; */
  width: 97%;

}

.nextbtn-vv2>.btndgn {
  color: #03565A;
  border: 2px solid #03565A;
  background-color: white;
  font-weight: 600;
  width: 150px;
  margin-right: 25px;
  margin-top: "-28px";


}



/* import Register Start */
.importAccordian {
  width: 990px;
  margin-left: 32px;
  margin-top: 19px;

}

.importBadge {

  background-color: #EDFFF3;
  color: green;
  border-radius: 3px;
  margin-top: -82px;
  margin-left: 160px;

}

.importCheckboxV2 {
  margin-left: 36rem;
}

.importDate {
  margin-left: 22px;
}

.importCalender {
  color: gray;
  margin-left: 15px;
}


/* end Register */

/* AddCompanies Style Start */
.companiesAccordion {
  width: 990px;
  margin-left: 32px;
  margin-top: 9px
}

.companiesAccordionDetail {
  margin-top: -30px;
  margin-left: 20px;
}

/* AddCompanies Style End */

/* Import Preset start */
.presetCard {
  width: 990px;
  margin-left: 32px;
  margin-top: 19px;
}

.presetCardContent {
  font-weight: bold;
  width: 101%;
  justify-content: space-between;

}

.presetContentSpan {
  margin-left: -2px;
  /* text-transform: bold; */
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #111111;
  margin-top: 2px
}

.presetContentCalender {
  margin-left: 22px;
}

.presetArrow {
  color: #03565A;
  margin-left: -22px;
  margin-top: 22px;

}

/* import Preset End */
.popover_v1 {
  margin-left: 22px;
}

.impbtn {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 35px;
  background: #03565A;
  border-radius: 6px;
  border: 2px solid #03565A;
  color: #fff;
  margin-right: 15px;
  width: 290px;
}

.impbtn:hover {
  background: #2DA6AC;
  border: 2px solid #2DA6AC;
  border-radius: 6px;

  color: #FFFFFF;
}

.impbtn2 {
  width: 290px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 35px;
  background: #fff;
  border: 2px solid #03565A;
  border-radius: 6px;
}

.impbtn2:hover {
  background: #fff;
  border: 2px solid #2DA6AC;
  border-radius: 6px;
  color: #2DA6AC;
}

.imprhead {
  margin-top: -10px;
  font-weight: 600;
  font-size: 30px;
  color: black;
  margin-left: 33px;
}

.imrh4 {
  font-weight: 700;
  font-size: 28px;
  line-height: 33.6px;
}

.impwhy {
  margin-left: -22px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #939393;
}

.ipmbtn1 {
  margin-left: 473px;
  border: 2px solid #03565A;
  color: #03565A;
  text-transform: none;
}

.impbtn2 {
  margin-left: 25px;
  background-color: #03565A;
  color: white;
  border: 2px solid #03565A;
  text-transform: none;
}

.imptoptex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 94%; */
  margin-top: 30px;
}

.containerpad {
  padding-left: 43px;
}

.imrstep {
  margin-left: 33px;
}

.imrl {
  margin-left: 750px;

}

.imrl>span {
  color: #03565A;
  font-weight: 550;
}

.imprwhy {

  margin-left: 2rem;
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #939393;

}

.imr {
  width: 937px;
}

.impr-contenth4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: -24px;
  margin-left: 26px;
}

.imprstep {
  margin-left: -22px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #929292;
}

.imprl {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.imprl-span {}

.imprcheck {
  margin-top: -30px;
  margin-left: 20px;
}

.imprcomp {
  margin-top: 20px;
}

.invbtndiv {
  margin-left: 278px;
}

.db-v2-head {
  font-weight: 700;
  font-size: 32px;
  line-height: 38.4px;
  /* width: 439px; */
}

.dbv2-dhead {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #939393;
}

.ir-heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  color: #000000;
}

.ir-con-head {
  line-height: 12px;
  font-weight: 600;
  font-size: 12px;
  color: #717171;
}

.ir-heading2 {
  font-weight: 700;
  font-size: 16px;
  size: 700;
  line-height: 24px;
  color: #111111;
  margin-top: 1px;
}

.ircon-head {
  font-weight: 700;
  font-size: 14px;
  /* line-height: 20px; */
  color: #696F79;
}

.ir-remark {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* margin-top: -36px; */
  margin-bottom: -7px;
  color: #696F79;

}

.iw-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #696F79;
}

.itmstep {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #929292;
}

.itmhead {
  font-weight: 700;
  font-size: 28px;
  line-height: 33.6px;
}

.itmpara {

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #939393;

}

.itmlab {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #696F79;
}

/* 16-08-2023 */
.main_bar {
  background-color: #f5f5f5;
}

.main_top {
  margin-top: 90px;
}

.input_box {
  width: 518px;
  margin: 0 auto;


}

.password-signin label {
  color: #696F79;
  font-weight: 600;
}

.password-signin {
  display: inherit;
}

.row_box {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 12px;

}

.btn_main {
  margin-top: 30px;
  margin-bottom: 10px;


}

.btn_main>.create_btn {

  background-color: #03565A;
  color: white;
  height: 65px;
  width: 100%;
  font-size: 20px;

}

.btn_main>.create_btn:hover {

  background-color: #03565A;


}

.w-20 {
  float: left;
  width: 20% !important;
}

.logiin-link-nav {
  width: 60%;
  float: left;
  margin-top: 28px;
}
.logo-img{
  width:123px;
  height: 50px;
  margin-top:15px;
}
.navbarend-link{
  text-align: right;
  color: #fff;
  font-size: 1.5rem;
  margin-top: 20px;
  font-weight: 500;
}
.wp-header-step{
  color: #929292;
  /* Caption/Bold */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.24px;
  text-transform: uppercase;
}
.wp-header-help{
  color: #343434;
text-align: right;
/* ParagraphMedium/Medium */
font-family: DM Sans;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}
.wp-header-title{
  color: #111;
/* H4/Bold */
font-family: Inter;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 33.6px */
letter-spacing: 0.56px
}
.wp-header-sub-title{
  color: #939393;
/* ParagraphMedium/Medium */
font-family: DM Sans;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}
.wp-setup-form-label{
  color: #696F79;

/* ParagraphSmall/Bold */
font-family: DM Sans;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 142.857% */
}
.wp-setup-form-input{
  border: 1px solid #8692A6 !important;
  background: #FFF;
}
.main-workspace-content{
  padding: 0px 40px;
}
.wp-selection, .wp-selection small{
  font-weight: 600;
}

.wp-datepicker .MuiTextField-root{
  width: 100%;
}
.wp-datepicker input {
  padding: 10px;
}

.sidbar-active{
  width: "29px";
  height: "29px";
  background-color: "#f3dbbe";
  border-radius: "50%";
}
.active-rounded{
  width: "11.74px";
  height: "11.74px";
  background-color: "#fdc27b";
  border-radius: "50%";
  position: "relative";
  top: "9px";
  left: "9px";
}


.sidbar-active{
  height: 35px;
  width: 35px;
  /* / border-radius: 15px; / */
  background-color: #f3dbbe;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -2px;
  
  
}
.sidbar-active>.active-rounded{
  width: 12px;
  height: 12px;
  background-color: #fdc27b;
  border-radius: 50%;
  position: relative;
  top: 11px;
  left: 11px;
}
/* pop over design */
/* Popover.css */

.popover-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  /* margin-right: 20px; */
  /* margin-top: 65px; */
  /* border: 2px solid red; */
}

.popover {
  /* margin-left: 3px; */
  position: relative;
  min-width: 290px;
  height: 280px;
  background-color: white;
  border-radius: 10px;
  margin-top: 150px;
  border: 2px solid #8692A6;
}

.popover-arrow {
  position: absolute;
  top: -20px;
  left: 37px;
  height: 30px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid white;
  width: 0;
  height: 0;
  -webkit-filter: drop-shadow(0 -2px 2px #AAA);
}
.calendar-container {
  position: relative;
}

.calendar-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the positioning as needed */
  transform: translateY(-50%);
}
/* material ui tabs */
.custom-tabs-class .custom-tab-class.Mui-selected {
  background-color: white;
  color: black;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.circle {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 100%; /* Makes the div circular */
  background-color: #FDC27B;
}
/* tab */
.custome-active-tab {
  color: black !important;
}

