.custom-table thead th:first-child {
  border-top-left-radius: 12px;
}

.custom-table thead th:last-child {
  border-top-right-radius: 12px;
}

.custom-table th, .custom-table td {
  border: 1px solid #ddd;
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0;
}

.custom-table .last-row td:first-child {
  border-bottom-left-radius: 12px;
}

.custom-table .last-row td:last-child {
  border-bottom-right-radius: 12px;
}

.header-text {
  color: #717171;
}
.table-row-span td {
  /* Ensure the first cell spans multiple rows */
  vertical-align: top; /* Adjust as needed */
}

.table-row-span td:nth-child(1) {
  /* Adjust the number based on which column you want to visually span */
  position: relative;
  height: auto; /* Adjust as needed */
}

.table-row-span td:nth-child(1)::before {
  /* Adjust the positioning and styling to create a visual row span effect */
  content: '';
  position: absolute;
  top: 0;
  left: 50%; /* Adjust to center the line */
  width: 2px; /* Adjust line thickness */
  height: 100%;
  background-color: #ccc; /* Adjust line color */
}

