.custom-table thead th:first-child {
    border-top-left-radius: 12px;
  }
  
  .custom-table thead th:last-child {
    border-top-right-radius: 12px;
  }
  
  .custom-table th, .custom-table td {
    border: 1px solid #ddd;
  }
  
  .custom-table {
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .custom-table .last-row td:first-child {
    border-bottom-left-radius: 12px;
  }
  
  .custom-table .last-row td:last-child {
    border-bottom-right-radius: 12px;
  }
  
  .general-box{
    height: 55px;
    background-color:#F8F8F8 ;
    border-radius: 15px;
   
  }
  .orange-circle{
    height:30px ;
    width: 30px;
    border-radius: 15px;
    background-color: orange;
   
  }
  .white-circle{
    height:30px ;
    width: 30px;
    border-radius: 15px;
    background-color: white;
  }
  .Primary-circle{
    height:30px ;
    width: 30px;
    border-radius: 15px;
    background-color: #03565A;
  }
 .no-border {
  border: none;
}

.text-left {
  text-align: left;
}

.last-cell {
  border-bottom-right-radius: 10px;
}
