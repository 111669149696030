.rixo-container{
    width: 90%;
    /* border: solid 1px #f00;  */
    margin: 0 auto;
    /* min-height: 800px     */
}
.rixo-containerfill{
    width: 100%;
    border: solid 1px #ccc;     
}
.rwp-btn{
    background-color: white;
    color: rgb(3, 86, 90);
    border: 2px solid rgb(3, 86, 90);
    text-transform: none;   
    font-weight: 500;
    width: 250px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    padding: 10px 5px;
    text-transform: capitalize;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.search-btn{
    background-color: white;
    color: rgb(3, 86, 90);
    border: 2px solid rgb(3, 86, 90);
    width: 360px;
    text-align: center;
    vertical-align: middle;
    font-size: 1rem;
    height: 50px;
    line-height: 0.5;
    border-radius: 0.25rem;
    padding: 5px 5px;
}
.rwp-title{
    font-size: 34px;
    font-weight: 600;
}
.MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller{
    overflow: hidden;
    margin-bottom: 0px;
    /* background-color: #f4f4f4; */
}
/* bookclouser */
.search-btnv2{
    background-color: white;
    color: rgb(3, 86, 90);
    border: 2px solid #E5E8E8 ;
    width: 360px;
    text-align: center;
    vertical-align: middle;
    font-size: 1rem;
    height: 50px;
    line-height: 0.5;
    border-radius: 0.25rem;
    padding: 5px 5px;
}
.rounded-left-top {
    border-radius: 10px 0 0 0;
  }