.ticketcard{
    width: 450px;
    height: 100%;
    border-radius: 10px;
    border: 1px solid #D6D6D6;
    background-color: #FFFFFF;
}
.closeticket{
    border: 2px solid #03565A;
    color: #03565A;
    text-align: center;
    border-radius: 12px;
    padding: 15px;
}
.new-textinput{
    background-color: #F8F8F8;
    border: 1px solid #D6D6D6;
    border-radius: 5px;
}


.logo-container {
    display: flex;
    align-items: center;
}

.logo-box {
    width: 50px;
    height: 50px;
    background-color: #D9BF66;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-right: 10px;
}
.logo-text {
    font-size: 16px;
    color: black;
}

.message-input {
    display: flex;
    align-items: center;
    background-color: #F8F8F8;
    border: 1px solid #D6D6D6;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .message-input input {
    border: none;
    outline: none;
    flex: 1;
    font-size: 16px;
    background-color: transparent;
  }
  
  .icon-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #D6D6D6;
    cursor: pointer;
  }
  
  .icon-button img {
    width: 24px;
    height: 24px;
  }

  /* .scroll-container::-webkit-scrollbar {
    display: none;
  }
   */

   .superadmin-bg {
    background-color:#D9BF66 ; /* Change to desired color */
  }
  
  .admin-bg {
    background-color:#03565A; /* Change to desired color */
  }

  .fixed-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white;
    border-bottom: 1px solid #ddd;
    padding: 10px;
  }

  .scrollable-chat-content {
    height:340px; /* Adjust based on header height */
    overflow-y: auto;
    padding-top: 20px;
  }

  .date-label-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .date-label-container hr {
    flex-grow: 1;
    border: none;
    border-top: 1px solid #ccc;
    margin-right: 20px;
  }
  
  .date-label-container .date-label {
    position: absolute;
    background-color: white;
    padding: 0 10px;
    font-size: 10px;
    color: gray;
  }

  .Table-headerText{
    color:  #717171;
  }
  /* .custom-font-500 {
    font-weight: 500 !important;
} */