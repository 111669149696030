

.conventionalMenu{
    display: flex;
    align-items: center;
    height: 58px;
    position:relative;
    background-color: var(--clr-bg);
    box-shadow: 4px 4px 8px #8b8b8b38 ;
    /* -webkit-box-shadow:4px 4px 8px #504f4f5d; */
    padding: 0 15px 0 10px;
    z-index: 1;
    position: sticky;
    top: 80px;
    min-width: 1045px;

}
@media (max-width: 1100px) {
    .conventionalMenu div p{
        display: none;
    
    }
    .conventionalMenu  div{
        margin: 1rem;
    }

    
  }

.conventionnalTable{
    /* box-shadow: -4px -4px 8px #5858586c ; */
}

.conventionalMenu div{
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
    cursor:pointer;
}

.filter{
    cursor: pointer;
}

.conventionalMenu div p{
    margin-top: 16px;
    margin-left: 2px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    text-align: left;

}

.uploadConventional{
    cursor:pointer
}

.delete i{
    cursor: pointer;
    color: rgba(207, 7, 7, 0.815);
}
.delete span{
    color: rgba(194, 13, 13, 0.815);
    cursor: pointer;
    font-size: 14px;
}

.conventionalMenu div:last-child{
    margin-left: auto;
    margin-right: 0;
}

.conventionalMenu div:hover{

    /* text-decoration: underline; */
}
.conventionalMenu div:last-child:hover{

    text-decoration: none;
}

.addTb>h4{
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: right;
    margin: 0;
    color: var(--clr-accent);
    
}

.rowHeader{
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns:  repeat(2, 11.6%) repeat(3, 7.6%) 11.6% repeat(1, 7.6%) repeat(3, 11.6%);

    /* grid-template-columns: auto auto auto auto auto auto auto auto auto auto; */
    /* background-color: cadetblue; */
}

.rowHeader div{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--clr-font-light);
    border-top: none;
    border-left: none;
    background-color:  #F6F6F6;
}

.ledgerCode input[type=checkbox]{
    box-sizing: border-box;
    margin-top: 5px;
    margin-right: 10px;
}

.rowHeader div p{
    color: var(--clr-font-mid);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-align: left;

}

.tabsinside>div{
    height:77vh
}
/* .addLedger{
    width: 100%;
    height: 2.5rem;
    padding: 10px;
    background-color: #f1ede9d9;
    position:fixed;
    bottom:'10px'
}
 */
