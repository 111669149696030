.nav-tabs .nav-link {
    margin-right: 10px;
}

.nav a {
    color:#495057 ;
    font-weight: 450;

}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: var(--clr-accent);
    background-color: #fff; 
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid #b1acac65;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}

.paneltab{
    border:1px solid #D6D6D6 !important;
    border-bottom: 0 !important;
    border-radius: 5px !important;
    background-color: transparent !important;
    color:black !important;
    min-width: 70px !important;
}
.paneltab.Mui-selected{
    color: #03565a !important;
    border: none !important;
    background-color: #fff !important;
    opacity: 1;
}
.paneltabcolor{
    color:black !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: black ;
}
.bsnotetab button{
    text-align: left;
}
.bsnotetab .MuiTab-wrapper{
    display: inline-block;
}
.custom-placeholder::placeholder {
    color: black;
  }


/* ? @link generate class name */
.hyper-link-ref {
    text-decoration: underline;
    text-decoration-color: lightblue;
}