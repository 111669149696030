.searchInput>i::before{
    position: relative;
    right:2rem
}

#gridScroll2{
    display:grid;
    grid-template-columns:repeat(3, 30%);
    grid-gap:2rem 4rem;
    margin:0rem 0;
    max-height:70vh;
    overflow-y:scroll;
    padding:0 2rem;
}


@media (max-width: 1100px){
    #gridScroll2{
        grid-template-columns: repeat(1, 60%);
    }
}


#gridScroll2::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
  }
   
#gridScroll2::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
  
#gridScroll2::-webkit-scrollbar-thumb {
  background-color: #03565A;
  outline: 1px solid white;
  border-radius: 10px
}