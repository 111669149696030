@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&display=swap');

.dm-sans{
    font-family: 'DM Sans', sans-serif;

}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');

.fs-inter{
    font-family: 'Inter' !important;
}

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200&display=swap');
.fs-Manrope{
    font-family: 'Manrope', sans-serif;
}