.active-section {
    background-color: #F8F8F8;
    font-weight: 700;
    margin-bottom: 15px;

}

.inactive-section {
    background-color: white;
    border: 1px solid #D6D6D6 !important;
    margin-bottom: 15px;

}

.chekbox {
    height: 20px;
    width: 20px;
    margin-left: -3px;
}

.import-btn {
    width: 200px;
    height: 40px;
}

.select-btn>.select-colum {
    width: 180px;
    background-color: white;
    border: 2px solid #03565A;
}

.exit-btnv1>.btn-exit {
    width: 200px;
    height: 60;
    background-color: white;
}

.save-btn>.save-btnv2 {
    width: 200px;
    height: 50px;
}

.import-btnv1>.btnv1-imort {
    width: 150px;
    height: 50px;
    border-radius: 5px;
}

.borderless-table {
    border: none !important
}

.oneline-icon {
    width: 3px;
    height: 25px;
    background-color: #717171;
}

.task-btn>.btn-task {
    width: 200px;
    height: 45px;
}

.shadow-btn {
    height: 50px;
    border: 1px solid #DFE2E7;
    border-radius: 8px;
    margin-top: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .modal-btn>.modalsave-btn{
    width: 200px;
    height: 50px;
  }
  .modal-btn>.modalsave-btnv1{
    width: 300px;
    height: 50px;
  }
  /* modalsave-btn */
  .border-none{
    border: none;
  }
.section-style{
    max-height: 500px;
    overflow-y: auto;
    padding-right: 16px;
    box-sizing: border-box;
}
.new-textinput{
    background-color:#F2F3F4 ;
    border: 1px solid #8692A6;
    border-radius: 5px;
}
/*  */
.mr-top{
    margin-top: -12px;
}
.datepicker-color{
    /* background-color: white; */
    border: 2px solid #8692A6;
}
.section{
    word-wrap: break-word; 
    overflow-wrap: break-word; 
    
}



  
  